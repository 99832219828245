import React from 'react'
import Image from "react-graceful-image";


export default function mainBanner({coverImage}) {
        return (
            <>
                <div className="details-big-img">
                    {/*<img src={coverImage} alt="" />*/}
                    <Image
                        src={coverImage}
                        retry={{ count: 10, delay: 2 }}
                        placeholderColor="#5f1a89"
                    />
                </div>
            </>
        )
}
