import React, { Component } from 'react'
import RecentBlog from '../../common/recentBlog';
import Comment from './comment';
import CommentForm from './commentForm';
import Image from "react-graceful-image";

export default function blog({blogData}){
    return(
        <>
            <aside className="col-lg-9 col-md-9 col-sm-6 col-xs-12">
                <div className="details-page">
                    <div className="details-img">
                        {/*<img src={blogData.image} alt="" />*/}
                        <Image
                            src={blogData.image}
                            alt={blogData.title}
                            retry={{ count: 10, delay: 2 }}
                            placeholderColor="#5f1a89"
                        />
                    </div>
                    <h2 className="title pt-30">{blogData.title}</h2>
                    <div className="details-contents" style={{ color: 'white', fontSize: '16px' }}>
                        <div dangerouslySetInnerHTML={{__html:blogData.detail}}></div>
                    </div>
                    <div className="comment-area">
                        <h2 className="title">comment's({blogData.total_comment})</h2>
                        {
                            blogData.comment.length ?
                                <Comment commentData={blogData.comment}/>
                                : null
                        }
                        <CommentForm blogId={blogData.id} />
                    </div>
                </div>
            </aside>
            <aside className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                <RecentBlog/>
            </aside>
        </>
    )

}
