import React from 'react'
import AboutSlider from './components/aboutCineplex/aboutSlider';
import RightAsideComponent from './components/aboutCineplex/rightAsideComponent';
import '../styles/about-us.css'

export default function aboutCineplex() {
    return (
        <>
            <div className="inner-page">
                <div className="faq-page pb-0 pt-40">
                    <div className="blog-page">
                        <div className="container">
                            <div className="row">
                                <aside className="col-lg-8 col-md-8 col-sm-6 col-xs-12">
                                    <div className="details-page">

                                        <AboutSlider />

                                        <div className="details-contents">
                                            <div className="details-content">
                                                <div className="details-dectiontion">
                                                    <h2 className="title_page mt-0">About Star Cineplex</h2>
                                                    <h4>The First Multiplex Cinema Theatre in Bangladesh</h4>
                                                    <br></br>
                                                    <p> STAR CINEPLEX : Show Motion Limited, incorporated in 19th
                                                        December 2002,
                                                        pioneered the modern Multiplex Movie Theater industry with
                                                        STAR Cineplex brand
                                                        in Bangladesh.</p>
                                                    <br></br>
                                                    <p> The First Multiplex Cinema Theatre in Bangladesh STAR
                                                        CINEPLEX : Show Motion
                                                        Limited, incorporated in 19th December 2002, pioneered the
                                                        modern Multiplex
                                                        Movie Theater industry with STAR Cineplex brand in
                                                        Bangladesh. With lucid vision
                                                        for the entertainment development in the country, the local
                                                        and foreign
                                                        promoters of Show Motion Ltd. started the first
                                                        international quality
                                                        state-of-the-art multiplex cinema theatre on 8th October
                                                        2004 in Bangladesh at
                                                        Bashundhara City Mall at Panthapath, Dhaka.</p>
                                                    <br></br>
                                                    <p>In January 2019, STAR Cineplex opened another branch in
                                                        Shimanto Shambhar.
                                                        The new branch has 3 fully digital cinema screens with
                                                        state-of-the-art 3D
                                                        Projection Technology, Silver Screens, Dolby-Digital Sound
                                                        and stadium seating.
                                                        One of the 3 halls will have ATMOS sound system which will
                                                        make the movie
                                                        watching even more brilliant. Hall 1 has a seating capacity
                                                        of 263, Hall 2 has
                                                        that of 253 and 3 has the capacity of 221, totaling to more
                                                        than
                                                        730 seats.
                                                    </p>
                                                    <br></br>
                                                    <p>STAR Cineplex, Bashundhara City shooping mall branch has six
                                                        fully digital cinema
                                                        screens with state-of-the-art 3D Projection Technology,
                                                        Silver Screens,
                                                        Dolby-Digital Sound and stadium seating. With a total
                                                        capacity of
                                                        1,600 seats the theater has large lobby with full concession
                                                        stands serving
                                                        pop-corns, soft drinks, ice-creams and many other items. In
                                                        addition to
                                                        scheduled shows, STAR Cineplex also caters to special
                                                        corporate bookings,
                                                        red-carpet premieres and private events. Visit
                                                        www.cineplexbd.com for updated
                                                        movie schedules and online ticket purchase
                                                    </p>
                                                    <br></br>
                                                    <h4>Our Goal</h4>
                                                    <br></br>
                                                    <p>Our goal is to provide the most modern, comfortable, cinema
                                                        viewing experience of
                                                        Hollywood and quality Dhallywood releases for a locally
                                                        adjusted price for our
                                                        youth and family centered audiences in Dhaka . We aim to be
                                                        the highest-value
                                                        entertainment provider in Bangladesh with
                                                        integrity &amp; professionalism in
                                                        every step.</p>
                                                    <div className="social-links">
                                                        <strong>Share :</strong>
                                                        <a href="" className="socila-fb"><span className="fa fa-facebook"></span></a>
                                                        <a href="" className="socila-tw"><span className="fa fa-twitter"></span></a>
                                                        <a href="" className="socila-sk"><span className="fa fa-skype"></span></a>
                                                        <a href="" className="socila-pin"><span className="fa fa-pinterest"></span></a>
                                                        <a href="" className="socila-ins"><span className="fa fa-instagram"></span></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </aside>

                                <RightAsideComponent />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
