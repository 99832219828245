import React from 'react'
import ECard from './../images/entertainment-card.png'
export default function (){
    return (
        <div className="inner-page">
            <div className="faq-page pt-75 pb-0">
                <div className="blog-page">
                    <div className="container">
                        <div className="row">
                            <div className="details-page">
                                <div className="details-contents">
                                    <div className="details-content">
                                        <div className="details-dectiontion">
                                            <div className=" col-lg-8 col-sm-12">
                                                <h2 className="title_page">Buy Tickets (About Entertainment Card)</h2>
                                                <p><strong>Entertainment Card purchase process:</strong></p>
                                                <p>
                                                </p><ul className="fa-ul" style={{fontSize:"13px"}}>
                                                <li><i className="fa-li fa fa-check-square"></i>Entertainment Cards are on sell at STAR Cineplex ticket counters with minimum loading amount of 2000.00 taka each.</li>
                                                <li><i className="fa-li fa fa-check-square"></i>Entertainment Card is completely free, the loaded amount is yours for ticket purchase.</li>
                                                <li><i className="fa-li fa fa-check-square"></i>No service charge or transaction fee.</li>
                                                <li><i className="fa-li fa fa-check-square"></i>Each card is valid for 150 days from the date of card purchase.</li>
                                                <li><i className="fa-li fa fa-check-square"></i>A system generated PIN number will be sent to your cell phone via sms (which will be required for online purchase).</li>
                                            </ul>
                                                <p></p>
                                                <p><strong>Ticket Purchase Process:</strong></p>
                                                <p>
                                                </p><ul className="fa-ul" style={{fontSize:"13px"}}>
                                                <li><i className="fa-li fa fa-check-square"></i>Entertainment Card can be presented at the ticket counter to purchase movie tickets from ticket counter (no PIN number is required).</li>
                                                <li><i className="fa-li fa fa-check-square"></i>Entertainment Card can be used for online ticket purchase (depends on service availability), process:</li>
                                                <li><i className="fa-li fa fa-check-square"></i>No service charge or transaction fee.</li>
                                                <li><i className="fa-li fa fa-check-square"></i>Log on to <a style={{ textDecoration: "underline", color: "red"}} href="http://27.147.140.82/cineplex"><b>www.cineplexbd.com</b></a> -click on Buy Ticket-scroll down and click on Purchase Tickets.</li>
                                                <li><i className="fa-li fa fa-check-square"></i>Enter your <b>Card Number – Password</b></li>
                                                <li><i className="fa-li fa fa-check-square"></i>Click on your desired <b> Movie</b></li>
                                                <li><i className="fa-li fa fa-check-square"></i>Click on your desired <b>Show Time</b></li>
                                                <li><i className="fa-li fa fa-check-square"></i>Click on your desired seat category <b>Regular or Premium</b></li>
                                                <li><i className="fa-li fa fa-check-square"></i>Click on your desired <b>Ticket Quantity</b></li>
                                                <li><i className="fa-li fa fa-check-square"></i>Check all information on display if ok enter <b>Cell Number</b> of the person will be picking up physical tickets from ticket counter (a confirmation code will be sent via sms to that number and it has to be presented at the ticket counter to collect ticket) Double check all information, Cell number – enter the <b>Digits</b> shown in the box - Click <b>Agree</b></li>
                                                <li><i className="fa-li fa fa-check-square"></i>You can save your transaction info In PDF format in your PC or you can take Print out for your record.</li>
                                                <li><i className="fa-li fa fa-check-square"></i>Your transaction has been completed.</li>
                                                <li><i className="fa-li fa fa-check-square"></i>Log Out.</li>
                                            </ul>
                                                <p></p>
                                                <p><strong>Note:</strong></p>
                                                <p>
                                                </p><ul className="fa-ul" style={{fontSize:"13px"}}>
                                                <li><i className="fa-li fa fa-check-square"></i>Please remember all online transactions are final, there will be refund or exchange.</li>
                                                <li><i className="fa-li fa fa-check-square"></i>This card is valid for 150 days (with no extension on refill).</li>
                                                <li><i className="fa-li fa fa-check-square"></i>Card cannot be refunded, amount loaded are to be used only for STAR Cineplex movie ticket purchase within the validity period.</li>
                                                <li><i className="fa-li fa fa-check-square"></i>Online ticket purchase depends on the service availability and a limited number of seats are reserved for online purchase.</li>
                                                <li><i className="fa-li fa fa-check-square"></i>Depends on the availability of seats.</li>
                                            </ul>
                                                <p></p>
                                            </div>
                                            <div className="col-lg-4 col-sm-12">
                                                <img className="img-thumbnail" src={ECard} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
