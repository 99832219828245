import React, {Component, useEffect} from 'react'
import Logo from '../../images/cine_logo2.png'
import ScrollToTop from "react-scroll-to-top";
import {basePath} from "../../config/configFile";

export default function footer(){

    // const backTop = (e) => {
    //     e.preventDefault()
    //     document.body.scrollTop = 0;
    //     document.documentElement.scrollTop = 0;
    // }

    useEffect(() => {
        document.getElementById("ft-content-footer").innerHTML = `
        <div className="fb-page fb_iframe_widget" data-href="https://www.facebook.com/MYCINEPLEX" data-tabs="" data-width="100%" data-height=""
                                            data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true" fb-xfbml-state="rendered"
                                            fb-iframe-plugin-query="adapt_container_width=true&amp;app_id=&amp;container_width=233&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2FMYCINEPLEX&amp;locale=en_US&amp;sdk=joey&amp;show_facepile=true&amp;small_header=false">
                                                <span style={{ "verticalAlign": "bottom", "width": "300px", "height": "181px" }}>
                                                    <iframe name="f23f44dcc983df" width="100%" height="auto" title="fb:page Facebook Social Plugin" frameBorder="0" allowtransparency="true"
                                                    allowFullScreen={true} scrolling="no" allow="encrypted-media" src="https://web.facebook.com/v3.3/plugins/page.php?adapt_container_width=true&amp;app_id=&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fconnect%2Fxd_arbiter.php%3Fversion%3D44%23cb%3Df8f18f743095b4%26domain%3Dlocalhost%26origin%3Dhttp%253A%252F%252Flocalhost%253A8888%252Ff3d60c536f53a6c%26relation%3Dparent.parent&amp;container_width=233&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2FMYCINEPLEX&amp;locale=en_US&amp;sdk=joey&amp;show_facepile=true&amp;small_header=false"
                                                    style={{ "border": " none", "visibility": "visible", "width": "100%", "height": "181px" }} className="">
                                                </iframe>
                                                </span>
                                            </div>
          `;
    },[])

    return (
        <>
            <footer className="footer-section">
                <div className="footer-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6 sm-width footer-col-1 xs-width">
                                <div className="ft-widget">
                                    <img src={Logo} alt="logo" width="120px"/>
                                    <div className="ft-content">
                                        <p>
                                            <strong>Show Motion Limited </strong> <br/>
                                            Level 8, Bashundhara City <br/>
                                            13/3 Ka, Panthapath, Tejgaon<br/>
                                            Dhaka 1215, Bangladesh.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6 sm-width xs-width footer-col-2"  >
                                <div className="ft-widget">
                                    <h2><span>Contact Us</span></h2>
                                    <div className="ft-content">

                                        <p>
                                            <strong>Phone Number </strong> <br />
                                            (+88) 09617660660 <br/>
                                            01755665544
                                        </p>
                                        <p>
                                            <strong>Email Address </strong> <br />
                                            <a href="mailto:info@cineplexbd.com"> info@cineplexbd.com</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6 sm-width footer-col-3 xs-width">
                                <div className="ft-widget">
                                    <h2><span>More at Cineplex</span></h2>
                                    <div className="ft-content">
                                        <ul>
                                            {/*<li><a href="/contact">Contact Us</a></li>*/}
                                            {/*<li><a href="/e-card">E-Gift Cards</a></li>*/}
                                            {/*<li><a href={basePath.cinebuzzBase}>Cineplex Magazine</a></li>*/}
                                            {/*<li><a href="/student-reward">Student Reward Program</a></li>*/}
                                            <li><a href="/privacy">Privacy & Policy</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-6 sm-width xs-width">
                                <div className="ft-widget">
                                    <h2><span>Like Us On Facebook</span></h2>
                                    <div className="ft-content" id="ft-content-footer">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 col-md-7 col-sm-7 col-xs-12 sm-width">
                                <div className="copyright-text">
                                    <p className="text-left">Copyright© {new Date().getFullYear()} <a href="#">Show Motion Limited</a> . All Rights
                                        Reserved.</p>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-5 col-sm-5 col-xs-12 sm-width">
                                <div className="copyright-text">
                                    {/*<p>Design &amp; Developed by <a href="https://sslwireless.com/" target="_blank">SSL Wireless</a></p>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <ScrollToTop smooth className="to-top" component={(
                // <div className="to-top" id="back-top">
                    <i className="fa fa-angle-up"></i>
                //</div>
            )} />
            {/*<div className="to-top" id="back-top" style={{"display":"none"}} onClick={backTop}>*/}
            {/*    <i className="fa fa-angle-up"></i>*/}
            {/*</div>*/}
        </>
    )
}
