import React  from 'react'
import {basePath} from "../../../config/configFile";
import moment from "moment";


export default function showTime({showTime, movieId}) {
      const designShowTime = (data) => {
            if (data.hall === 1) {
                  return (
                      <li className="time-select__item">
                            {data.time}
                      </li>)
            } else if (data.hall === 2) {
                  return (
                      <li className="time-select__item_6">
                            {data.time}
                      </li>)
            } else if (data.hall === 3) {
                  return (
                      <li className="time-select__item_7">
                            {data.time}
                      </li>)
            }
      }
      return (
          <>
                <div className="show-time-wrapper odd pb-2">
                      <div style={{display:'content'}}>
                            {
                                  showTime.length ? showTime.map((showTimeData, key) => {
                                        return <div className={"col-md-"+Math.floor(12/showTime.length)} key={key}>
                                              <h5 className="showtime-heading">{showTimeData.date_name}<br />{showTimeData.date}</h5>
                                              <div className="time-select time-select__group group--first">
                                                    <ul className="items-wrap">
                                                          {
                                                                showTimeData.slot.map((slot_time,key) => {
                                                                      return <React.Fragment key={key}>
                                                                            {designShowTime(slot_time)}
                                                                      </React.Fragment>
                                                                })
                                                          }
                                                          {/*<a href="#" data-toggle="modal" data-target="#buy_ticket_modal" className="btn btn-button btn-block red-bg button-1">Get Tickets</a>*/}
                                                          <a href={`${basePath.ticketBase}?movie_id=${movieId}&date=${moment(showTimeData.date,"Do, MMMM YYYY").format('YYYY-MM-DD')}`} target="_blank" className="btn btn-button btn-block red-bg button-1">Get Tickets</a>
                                                    </ul>
                                              </div>
                                        </div>
                                  }) : ('')
                            }
                      </div>
                </div>
          </>

      )
}
