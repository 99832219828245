document.querySelectorAll('[data-dismiss=modal]').forEach((element) => {
    element.addEventListener('click', () => {
        // Get the current open modal (the modal with the show class).
        const modal = document.querySelector('.modal.show');
        // If an open modal is found, close it.
        if (modal) {
            closeModal(modal);
        }
    });
});

document.querySelectorAll('.modal').forEach((modal) => {
    modal.addEventListener('click', (event) => {
        // We have to check that the element that was clicked is actually the .modal element,
        // because this event will fire when children of the .modal element are clicked too (like the modal dialog).
        if (event.target === modal) {
            closeModal(modal);
        }
    });
});

export const openModal = (modal) => {
    const backdrop = document.createElement('div');
    // Remove the show from the initial classList, we will add it in the timeout
    //
    // backdrop.classList.add('modal-backdrop', 'fade', 'show');
    backdrop.classList.add('modal-backdrop', 'fade');
    document.body.classList.add('modal-open');
    document.body.appendChild(backdrop);
    modal.style.display = 'block';
    modal.setAttribute('aria-hidden', 'false', 'show');

    // We don't need to specify the milliseconds in this timeout, since we don't want a delay,
    // we just want the changes to be done outside of the normal DOM thread.
    setTimeout(() => {
        // Move adding the show class to inside this setTimeout
        modal.classList.add('show', 'in');
        // Add the show class to the backdrop in this setTimeout
        backdrop.classList.add('show','in');
    });
}

export const closeModal = (modal) => {
    const backdrop = document.querySelector('.modal-backdrop.fade.show');
    modal.setAttribute('aria-hidden', 'true');
    backdrop.classList.remove('show', 'in');
    // We want to remove the show class from the modal outside of the regular DOM thread so that
    // transitions can take effect
    setTimeout(() => {
        modal.classList.remove('show', 'in');
    });

    // We want to set the display style back to none and remove the backdrop div from the body
    // with a delay of 500ms in order to give their transition/animations time to complete
    // before totally hiding and removing them.
    setTimeout(() => {
        modal.style.display = 'none';
        backdrop.remove();
        document.querySelector('body').classList.remove('modal-open')
    }, 500); // this time we specified a delay
}