import React, { useEffect, useState } from "react";
import Blog from "./components/blogSingle/blog";
import { useParams } from "react-router-dom";
import { apiCall } from "../api/apiCall";
import { Helmet } from "react-helmet";
export default function blogDetail(props) {
    const params = useParams();
    const [blogData, setBlogData] = useState(null);
    const [metaTags, setMetaTags] = useState([]);
    const getBlogDetail = async () => {
        const res = await apiCall("/blog/" + params.id + "/detail", {});
        const currentMetaTags = [
            { name: "og:title", content: res?.title },
            { name: "og:description", content: res?.title },
            { name: "og:image", content: res?.image },
            { name: "og:url", content: `${window.location.origin}/news/${res?.id}` },
        ];

        setMetaTags(currentMetaTags);
        setBlogData(res);
    };

    useEffect(() => {
        getBlogDetail();
    }, [params.id]);

    useEffect(() => {
        metaTags.forEach(({ name, content }) => {
            const meta = document.createElement("meta");
            meta.name = name;
            meta.content = content;
            document.head.insertAdjacentElement("afterbegin", meta);
            return () => {
                document.head.removeChild(meta);
            };
        });
    }, [metaTags]);

    return (
        <>
            {/* <Helmet>
                <title>{blogData?.title}</title>
                <meta property="og:title" content="Cineplex | Latest News" />
                <meta property="og:description" content={blogData?.title} />
                <meta property="og:image" content={blogData?.image} />
                <meta property="og:url" content={window.location.origin + "/news/" + blogData?.id} />
            </Helmet> */}
            <div className="inner-page">
                <div className="faq-page pt-75 pb-0">
                    <div className="blog-page">
                        <div className="container">
                            <div className="row">{blogData ? <Blog blogData={blogData} /> : ""}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
