export const GET_LOCATION = '/location'
export const GET_TOKEN = '/login'
export const MOVIE_LIST = '/movie-list'
export const SLIDER_LIST = '/slider'
export const MOVIE_SEARCH_LIST = '/movie-search-list'
export const BLOG_LIST = '/blog'
export const NOTICE = '/notices'
export const POLL_MOVIE_LIST = '/poll-movie'
export const VOTE_POLL = '/poll'
export const VOTE_MOVIE_LIST = '/vote-movie-list'
export const VOTE = '/vote'
export const RECENT_BLOGS = '/recent-blog'
export const POST_COMMENT = '/comment'
export const MOVIE_SHOW_TIME = '/movie-show-time'
export const ABOUT_SLIDER = '/about-slider'
export const GALLERY_LIST = '/gallery-list'
export const FOOD_LIST = '/food-list'
export const FOOD_RECOMMENDATION = '/food-recommendation'
export const FOOD_VOTE = '/vote-food'
export const CONTACT = '/contact'
export const STUDENT_REWARD = '/student-reward'
export const CONCESSION_LIST = '/concession-list'
export const TICKET_PRICE = '/ticket-price-page-html'
