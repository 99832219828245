import React, { useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';
import Encard02 from '../../../images/entertainment-card02.png'
import Encard01 from '../../../images/entertainment-card.png'
import ModalVideo from 'react-modal-video'
import '../../../styles/modal-video.css'
import SliderMovie from '../../common/sliderMovie'
import {basePath} from "../../../config/configFile";
import {connect} from "react-redux";

function featuredList({ featureListRunning, featureListUpcomming, locationData }) {
    const [totalRunningMovie, setTotalRunningMovie] = useState(featureListRunning ? featureListRunning.length : 0)
    const [totalUpcomingMovie, setTotalUpcomingMovie] = useState(featureListUpcomming ? featureListUpcomming.length : 0)
    const [isOpen, setIsOpen] = useState(false)
    const [url, setUrl] = useState(null)

    const [owlResponsive, setOwlResponsive] = useState({
        0: {
            stagePadding: 80,
            margin: 20,
            items: 1,
            loop: (totalRunningMovie > 1)
        }
        , 480: {
            items: 2,
            loop: (totalRunningMovie > 2)
        }
        , 568: {
            items: 2,
            loop: (totalRunningMovie > 2)
        }
        , 768: {
            items: 3,
            loop: (totalRunningMovie > 2)
        }
        , 980: {
            items: 3,
            loop: (totalRunningMovie > 3)
        }
        , 1080: {
            items: 3,
            loop: (totalRunningMovie > 3)
        }
        , 1200: {
            items: 4,
            loop: (totalRunningMovie > 4)
        }
        , 1300: {
            items: 5,
            loop: (totalRunningMovie > 4)
        }
        , 1600: {
            items: 6,
            loop: (totalRunningMovie > 6)
        }
        , 1920: {
            items: 7,
            loop: (totalRunningMovie > 6)
        }
    })

    const [owlResponsiveUp, setOwlResponsiveUp] = useState({
        0: {
            stagePadding: 80,
            margin: 20,
            items: 1,
            loop: (totalUpcomingMovie > 1)
        }
        , 480: {
            items: 2,
            loop: (totalUpcomingMovie > 2)
        }
        , 568: {
            items: 2,
            loop: (totalUpcomingMovie > 2)
        }
        , 768: {
            items: 3,
            loop: (totalUpcomingMovie > 2)
        }
        , 980: {
            items: 3,
            loop: (totalUpcomingMovie > 3)
        }
        , 1080: {
            items: 3,
            loop: (totalUpcomingMovie > 3)
        }
        , 1200: {
            items: 4,
            loop: (totalUpcomingMovie > 4)
        }
        , 1300: {
            items: 5,
            loop: (totalUpcomingMovie > 4)
        }
        , 1600: {
            items: 6,
            loop: (totalUpcomingMovie > 6)
        }
        , 1920: {
            items: 7,
            loop: (totalUpcomingMovie > 6)
        }
    })

    /**
     * @param {{ preventDefault: () => void; }}
     * @param {string} url
     */
    const handleVideoPlay = (e,url) => {
        e.preventDefault();
        setIsOpen(true)
        var url_id = url.split('=')[1]
        setUrl(url_id)
    }

    useEffect(() => {
        $('ul.tab-menu li').click(function() {
            if($(this).attr('data-tab') === "buy_tickets_tab"){
                $('.show-all-movies-btn').addClass('d-none')
            } else {
                $('.show-all-movies-btn').removeClass('d-none')
            }
            var tab_id = $(this).attr('data-tab');

            $('ul.tab-menu li').removeClass('current');
            $('.tab_elements').removeClass('current');

            $(this).addClass('current');
            $("." + tab_id).addClass('current');
        });
    }, [locationData])

    return (
        <>
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId={url} onClose={() => setIsOpen(false)} />
            <section className="category-movie">
                <div className="haddings">
                    <div className="container">
                        <div className="row d-flex align-items-center showing-tab">
                            <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                                <div className="hadding-area hadding-with-bg" style={{ marginBottom: '0' }}>
                                    <div className="cat-menu">
                                        <ul className="tab-menu">
                                            <li className={(featureListRunning && featureListRunning.length) ? "current" : null} data-tab="latestmovie">
                                                <a href="#latestmovie" className="">
                                                    <span>Now Showing</span>
                                                </a>
                                            </li>
                                            <li className={(featureListRunning && !featureListRunning.length) ? "current" : null} data-tab="commingsoon">
                                                <a href="#commingsoon" className=""><span>Coming Soon</span>
                                                </a>
                                            </li>
                                            <li data-tab="buy_tickets_tab">
                                                <a href="#buy_tickets_tab" className=""><span>Buy Tickets</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/show-time" className="tab-change"><span>Show Times</span></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 view-all-movie text-right sm-none show-all-movies-btn">
                                <Link to="/movie-list"
                                    className="btn btn-button button-1 white-outline view-all-movies-1" style={{ marginBottom: '0' }}>View
                                        All Movies</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="category-movie-items">
                    <div className="category-items">
                        <div className="tab-content">
                            <div id="latestmovie" className={(featureListRunning && featureListRunning.length) ? "latestmovie tab_elements current" : "latestmovie tab_elements"} role="">
                                {
                                    (featureListRunning && featureListRunning.length) ?
                                        <OwlCarousel className="category-slide"
                                            autoplay={true}
                                            autoplaySpeed={300}
                                            nav={true}
                                            stagePadding={80}
                                            margin={30}
                                            dots={false}
                                            smartSpeed={1200}
                                            navText={["<i class='flaticon-send'></i>", "<span class='flaticon-send'></span>"]}
                                            autoplayHoverPause={true}
                                            responsive={owlResponsive}
                                        >
                                            {
                                                (featureListRunning && featureListRunning.length) ?
                                                    featureListRunning.map( (movie, key) => {
                                                        return <SliderMovie handleVideoPlay={handleVideoPlay} movie={movie} isRunning={true} key={key}/>
                                                    })
                                                    : null
                                            }
                                        </OwlCarousel> : null
                                }

                            </div>

                            <div id="commingsoon" className={ (featureListUpcomming && !featureListRunning.length) ? "commingsoon tab_elements current" : "commingsoon tab_elements"} role="">
                                {
                                    (featureListUpcomming && featureListUpcomming.length) ?
                                        <OwlCarousel
                                            autoplay={true}
                                            autoplaySpeed={300}
                                            nav={true}
                                            stagePadding={80}
                                            margin={30}
                                            dots={false}
                                            smartSpeed={1200}
                                            navText={["<i class='flaticon-send'></i>", "<span class='flaticon-send'></span>"]}
                                            autoplayHoverPause={true}
                                            responsive={owlResponsiveUp}
                                        >
                                           {
                                               (featureListUpcomming && featureListUpcomming.length) ?
                                                featureListUpcomming.map( (movie, key) => {
                                                        return <SliderMovie handleVideoPlay={handleVideoPlay} movie={movie} isRunning={false} key={key}/>
                                                    })
                                                    : null
                                            }
                                        </OwlCarousel> : null
                                }
                            </div>

                            <div id="buy_tickets_tab" className="tab_elements buy_tickets_tab" role="">
                                <div className="container">
                                    <div className="row d-table">
                                        <div className="col-md-3">
                                            <div className="ticket_list_item ticket_list_item_tabs">
                                                <img src={Encard02} alt="" />
                                                <h3 style={{
                                                    margin: '20px 0px',
                                                    fontWeight: 400,
                                                    color: '#fff'
                                                }}>PURCHASE A TICKET</h3>
                                                <h4>through Card/Mobile Banking/Internet Banking</h4>
                                                <a href={basePath.ticketBase} target="_blank" rel="noopener noreferrer" className="btn btn-button red-bg button-1 white-outline">Buy Now</a>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="ticket_list_item ticket_list_item_tabs">
                                                <img src={Encard01}
                                                    alt="" />
                                                <h3 style={{
                                                    margin: '20px 0px',
                                                    fontWeight: 400,
                                                    color: '#fff'
                                                }}>PURCHASE A TICKET</h3>
                                                <h4>Through Entertainment Card</h4>
                                                <a href="#" onClick={(e) => e.preventDefault()}
                                                    target="_blank" rel="noopener noreferrer"
                                                    className="btn btn-button red-bg button-1 white-outline">Buy Now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 view-all-movie text-center show-all-movies-btn d-lg-none">
                                <Link to="/movie-list"
                                    className="btn btn-button button-1 white-outline view-all-movies-1" style={{ marginBottom: '0' }}>View
                                        All Movies</Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = function(state) {
    return {
        locationData: state.location,
    }
}

export default connect(mapStateToProps)(featuredList);
