import React, {Component, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import Synopsis from './synopsis';
import Cast from './cast';
import Producer from './producer';
import Writer from './writers';
import Gallery from './gallery';
import ShowTime from './showTime';
import MovieInfo from './movieInfo';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import ModalVideo from "react-modal-video";
import {connect} from "react-redux";
import Image from "react-graceful-image";


function movieDetail({locationData,movieDetail}){

    const [url, setUrl] = useState(null)
    const [isOpen, setIsOpen] = useState(false)

    const handleVideoPlay = (e,url) => {
        e.preventDefault();
        setIsOpen(true)
        const url_id = url.split('=')[1]
        setUrl(url_id)
    }

    useEffect(()=>{
        setUrl(null)
    },[locationData])

    return(
        <>
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId={url} onClose={() => setIsOpen(false)} />
            <div className="container">
                <div className="details-page">
                    <div className="details-contents">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="details-content">
                                    <div className="details-reviews">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-5 col-xs-12">
                                                <div className="dec-review-img">
                                                    {/*<img src={movieDetail.profile_image} alt="" />*/}
                                                    <Image
                                                        src={movieDetail.profile_image}
                                                        alt={movieDetail.movie_title}
                                                        placeholderColor="#5f1a89"
                                                        height="640"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-8 col-md-8 col-sm-7 col-xs-12">
                                                <MovieInfo movieDetail={movieDetail} handleVideoPlay={handleVideoPlay}/>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        (movieDetail.getdetail != null) ?
                                            <React.Fragment>
                                                <div className="details-dectiontion">
                                                    {
                                                        movieDetail.getdetail.synopsis?
                                                            <Synopsis sysData={movieDetail.getdetail.synopsis} />
                                                            :''
                                                    }
                                                    {
                                                        movieDetail.getdetail.cast?
                                                            <Cast castData={movieDetail.getdetail.cast} />
                                                            :''

                                                    }
                                                    {
                                                        movieDetail.getdetail.producers?
                                                            <Producer producerData={movieDetail.getdetail.producers} />
                                                            :''
                                                    }
                                                    {
                                                        movieDetail.getdetail.writers?
                                                            <Writer writerData={movieDetail.getdetail.writers} />
                                                            :''
                                                    }
                                                </div>
                                                {
                                                    movieDetail.getdetail.gallery?
                                                        <Gallery galleryData={movieDetail.getdetail.gallery} />
                                                        : ""
                                                }
                                            </React.Fragment>
                                            : ('')

                                    }
                                    {
                                        (movieDetail.status == 1)
                                            ?
                                            <React.Fragment>
                                                <div className="hadding-area">
                                                    <h2 id="movie_details_showtime" className="title" id="movie_detail_show_time">Showtime
                                                        <br/>
                                                        <p style={{fontSize:'12px', paddingTop:'0' , paddingBottom:'0'}}>[ {
                                                            locationData ?
                                                                locationData.location_name
                                                                :
                                                                "No Location Selected"
                                                        } ]</p><small data-toggle="modal"  data-target="#loc_cng_modal" style={{fontSize:'12px'}}>Change Location</small>
                                                    </h2>
                                                </div>
                                                {
                                                    locationData ?
                                                        movieDetail.show_time_data.length ?
                                                            <ShowTime showTime={movieDetail.show_time_data} movieId = {movieDetail.movie_id}/>
                                                            : <div className="">
                                                                <h5 className="showtime-heading" style={{paddingLeft: '30px', paddingTop: '40px'}}>
                                                                    <p>No schedule found in {locationData.location_name}</p>
                                                                </h5>
                                                            </div>
                                                        :
                                                        <div className="">
                                                            <h5 className="showtime-heading" style={{paddingLeft: '30px', paddingTop: '40px'}}> 
                                                                <p> Please select a location first </p> 
                                                            </h5>
                                                        </div>
                                                }
                                            </React.Fragment>
                                            : ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = function(state) {
    return {
        locationData: state.location
    }
}

export default connect(mapStateToProps)(movieDetail)
