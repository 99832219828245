import React, { Component } from 'react'
import MovieInfo from './movieInfo';
import Show from './show';

export default function allShowTimes({showTimeData}){
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        {
                            showTimeData.map((show_time,key) => (
                                <div className="show-time-wrapper odd" key={key}>
                                    {
                                        show_time.show_time.length?
                                        <div className="row display-flex">
                                            <MovieInfo movieInfo={show_time.movie_detail} movieId={show_time.id}/>
                                            <Show time={show_time.show_time} movieId={show_time.movie_id}/>
                                        </div>
                                        :null
                                    }
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
