import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import mainReducer from './reducer'
import logger from "redux-logger";

// export const store = createStore(mainReducer)

export const store =
  process.env.NODE_ENV === "development"
    ? createStore(mainReducer,applyMiddleware(logger, thunk))
    : createStore(mainReducer,applyMiddleware(thunk));
