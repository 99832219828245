import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default function searchBoxData({list}) {
      return (
            <div className="search-result-box col-md-12">
                  {
                        list.length ?
                              list.map((movie, key) => {
                                    return <div className="row each_search_list" key={key}>
                                          <div className="col-md-3 search-movie-image">
                                                <img src={movie.profile_image} />
                                          </div>
                                          <div className="col-md-9 search-movie-name">
                                                <Link to={`/detail/${movie.id}`}>{movie.movie_title}</Link>
                                          </div>
                                    </div>
                              })
                              : <div className="col-md-12">
                                    No movie found
                                </div>
                  }
            </div>
      )
}