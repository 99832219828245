import React, { Component } from 'react'

export default function synopsis({sysData}){
    return(
        <>
            <h2 className="title hadding-area">SYNOPSIS</h2>
            <div dangerouslySetInnerHTML={{__html:sysData}}></div>
        </>
    )
}
