import React, {useEffect, useState} from 'react'
import {postCallHeader} from "../api/apiCall";
import {TICKET_PRICE} from "../api/apiRoutes";

export default function TicketPrice(){
    const [data, setData] = useState(null)

    const getTicketPage = async () => {
        let result = await postCallHeader(TICKET_PRICE)
        setData(result)
    }

    useEffect(() => {
        getTicketPage()
    }, [])

    return(
        <div className="inner-page">
            <div className="faq-page pt-50 pb-0">
                <div className="blog-page">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12"> 
                                <div className="details-page ticket-price">
                                    <div className="details-contents">
                                        <div className="details-content"> 
                                            <div className="details-dectiontion">
                                                <div dangerouslySetInnerHTML={{__html: data}}></div>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}