import moment from 'moment';
import React, { useContext } from 'react'
import { basePath } from "../../../config/configFile";
import { ShowTimeFilterContext } from '../../../context/showTimeFilterContext'
import ArrowImage from '../../../images/left-arrow.svg'
import RightArrowImage from '../../../images/right-arrow.svg'
import Image from "react-graceful-image";

export default function show({ time, movieId, changeDate, isDateInRange, isDisable }) {
    const { dateRange } = useContext(ShowTimeFilterContext);

    const designShowTime = (data) => {
        if (data.hall === 1) {
            return (
                <li className="time-select__item">
                    {data.time}
                </li>)
        } else if (data.hall === 2) {
            return (
                <li className="time-select__item_6">
                    {data.time}
                </li>)
        } else if (data.hall === 3) {
            return (
                <li className="time-select__item_7">
                    {data.time}
                </li>)
        }
    }

    return (
        <div className="col-md-8 col-sm-12 col-xs-12">
            {/*<div className="bg-dark d-flex justify-content-between" style={{ padding: '10px' }}>*/}
            {/*    <span className='text-white'>*/}
            {/*        <a href="#" className={isDisable ? 'text-muted' : 'text-white'} onClick={(e) => { e.preventDefault(); isDisable ? null : changeDate('minus') }}>*/}
            {/*            <Image*/}
            {/*                src={ArrowImage}*/}
            {/*                style={{ width: '20px' }}*/}
            {/*                retry={{ count: 10, delay: 2 }}*/}
            {/*                placeholderColor="#5f1a89"*/}
            {/*                className="mr-2"*/}
            {/*            />  Last 7 Days*/}
            {/*        </a>*/}
            {/*    </span>*/}

            {/*    <span className='text-white'>*/}
            {/*        {moment(dateRange[0]).format('ll')} - {moment(dateRange[1]).format('ll')}*/}
            {/*    </span>*/}

            {/*    <span className='text-white'>*/}
            {/*        <a href="#" className='text-white' onClick={(e) => { e.preventDefault(); changeDate('add') }}>*/}
            {/*            Next 7 Days*/}
            {/*            <Image*/}
            {/*                src={RightArrowImage}*/}
            {/*                style={{ width: '20px' }}*/}
            {/*                retry={{ count: 10, delay: 2 }}*/}
            {/*                placeholderColor="#5f1a89"*/}
            {/*                className="ml-2"*/}
            {/*            />*/}
            {/*        </a>*/}
            {/*    </span>*/}
            {/*</div>*/}

            <div className="d-flex flex-wrap h-100">
                {
                    time.length ? time.map((showTime, key) => {
                        return <React.Fragment key={key}>
                            <div className={'col'}>
                                <h5 className="showtime-heading">{showTime.date_name}<br />{showTime.date}</h5>
                                <div className="time-select time-select__group group--first">
                                    <ul className="items-wrap">
                                        {
                                            showTime.slot.map((slot_time, key) => {
                                                return <React.Fragment key={key}>
                                                    {designShowTime(slot_time)}
                                                </React.Fragment>
                                            })
                                        }
                                        <a href={`${basePath.ticketBase}?movie_id=${movieId}&date=${moment(showTime.date,"Do, MMMM YYYY").format('YYYY-MM-DD')}`} className="btn btn-button btn-block red-bg button-1 get-ticket-btn" target="_blank">Get Ticket</a>
                                    </ul>
                                </div>
                            </div>
                        </React.Fragment>
                    }) : null
                }
            </div>
        </div>
    )
}
