import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { CookiesProvider } from 'react-cookie'
import {basePath} from './config/configFile'
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
//import js
import $ from 'jquery'
import 'moment'
import 'bootstrap/dist/js/bootstrap.min'
import './js-files/jquery.prettyPhoto'

import axios from 'axios'

axios.defaults.baseURL = basePath.base_path

ReactDOM.render(<CookiesProvider> <App /> </CookiesProvider>, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

serviceWorker.unregister();
