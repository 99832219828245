/**
* @param {object} data
*/
export const SET_TOKEN = (data) => ({
    type: 'SET_TOKEN',
    payload: data
});

/**
  * @param {object} data
  */
export const SET_LOCATION = (data) => ({
    type: 'SET_LOCATION',
    payload: data
});

export const HEADER_LOAD = (data) => ({
    type: 'HEADER_LOAD',
    payload: data
});
