import React, { useEffect, useState } from 'react'
import swal from 'sweetalert';
import { VOTE_MOVIE_LIST, VOTE } from "../../../api/apiRoutes";
import { apiCall, postCallWithresponse } from "../../../api/apiCall";
import {loadReCaptcha, ReCaptcha} from "react-recaptcha-v3";
import {store} from "../../../redux/store";


export default function vote() {
    const state = store.getState()
    const [movieList, setMovieList] = useState([])
    const [recaptchaToken,setRecaptchaToken] = useState('')
    const getVoteMovieList = async () => {
        setMovieList(await apiCall(VOTE_MOVIE_LIST, {}) ?? [])
    }

    const verifyCallback = (data) => {
        setRecaptchaToken(data)
    }

    /**
     * @param {{ preventDefault: () => void; target: { value: any; }; }} e
     */
    const voteMovie = async (e) => {
        e.preventDefault();
        const result = await postCallWithresponse(VOTE, {
            user_id: state.user_id,
            movie_id: parseInt(e.target.value),
            recaptchaToken:recaptchaToken
        })
        if(result?.code === 200){
            getVoteMovieList()
            swal('Success:', result?.message, 'success');
        } else if(result?.code === 401){
            swal('Success:', 'Your session expired please reload and submit again', 'error');
        } else {
            swal('Error:', result?.message, 'error');
        }
    }

    useEffect(() => {
        loadReCaptcha('6Lddr8QUAAAAABh9QxGA9GxgEQzwBA4Y1NObXEcH');
        getVoteMovieList()
    }, [])

    return (
        <>
            <div className="widget mb-10">
                <div className="widget-title">
                    <i className="fa fa-list"></i><span>Vote For Movie</span>
                </div>
                <div className="widget-content">
                    <form>
                        <ul className="list-group custom-list-group">
                            {
                                movieList.length ?
                                    movieList.map((movie, key) => {
                                        return <li className="list-group-item" key={key}> 
                                            <input type="checkbox" name="movie_vote_id" onClick={voteMovie} id={'vote-' + movie.id} value={movie.id} /> 
                                            <span className="movie-name" style={{width:'80%'}}>{movie.movie_name} </span> 
                                            <span className="badge" id={"rs-" + movie.id}>{movie.vote}</span>
                                        </li>
                                    })
                                    :
                                    ('')
                            }
                        </ul>
                    </form>
                    <ReCaptcha
                        sitekey="6Lddr8QUAAAAABh9QxGA9GxgEQzwBA4Y1NObXEcH"
                        action='homepage'
                        verifyCallback={verifyCallback}
                    />
                </div>
            </div>
        </>
    )
}
