import React from 'react'
export default function () {
    return (
        <div className="container" style={{color:'#ffffff'}}>
            <div className="page-header">
                <h2>Privacy Policy</h2>
            </div>
            <div className="bs-callout bs-callout">
                <h4>Who is STAR Cineplex</h4>
                <p>&nbsp;</p>
                <p>
                    The First Multiplex Cinema Theatre in Bangladesh, STAR Cineplex is the brand name of Show Motion Limited, pioneered in modern Multiplex Movie Theater industry in the country. With lucid vision for the entertainment development in the country, the local and foreign promoters of Show Motion Limited started the first international quality state-of-the-art multiplex cinema theatre on 8th October 2004 in Bangladesh at Bashundhara City Mall, Panthapath, Dhaka.
                </p>
            </div>
            <p>&nbsp;</p>
            <div className="bs-callout bs-callout">
                <h4>Our Policy</h4>
                <p>&nbsp;</p>
                <p>
                    Welcome to the web site (the “Site”) of Start Cineplex (cineplexbd.com). This Site is operated by STAR Cineplex and has been created to provide information about our company, whether accessible to you via web, mobile app or other platform (our services, together with the Site, are the “Services”) by visitors and users of the Services (“you” and/or “your”).
                </p>
                <p>
                    STAR Cineplex collects information about you when you use our mobile applications, websites, and other online products and services (collectively, the “Services”) and through other interactions and communications you have with STAR Cineplex.
                </p>
                <p>
                    Please read this privacy policy carefully so that you understand how we will treat your information. By using any of our Services, you confirm that you have read, understood and agree to this privacy policy. If you do not agree to this policy, please do not use any of the Services. If you have any queries, please email us at <a href="mailto:customerservices@cineplexbd.com" target="_top" style={{color: '#b945ff'}}>customerservices@cineplexbd.com</a>
                </p>
            </div>
            <p>&nbsp;</p>
            <div className="bs-callout bs-callout">
                <h4>Scope and Application</h4>
                <p>&nbsp;</p>
                <p>
                    This Privacy Statement (“Statement”) applies to persons anywhere in the world who use our apps, websites, platforms, technologies to request STAR Cineplex Services.
                </p>
            </div>
            <p>&nbsp;</p>
            <div className="bs-callout bs-callout">
                <h4>Information We Collect</h4>
                <p>&nbsp;</p>
                <p>
                    When you interact with us through the Services, we collect information about you in the following general categories:
                </p><ul className="">
                <li><i className="fa fa-angle-right"></i>
                    &nbsp; Location Information. As a condition of providing Users with nearby Movie Theater Location, or other services via the STAR Cineplex platform, your precise location data must be provided to STAR Cineplex via the app that that you use. Once the STAR Cineplex app has permission to access location services through the permission system used by your mobile operating system (“platform”), we will collect the precise location of your device when the app is running in the foreground or background. We may also derive your approximate location from your IP address.
                </li>

                <li><i className="fa fa-angle-right"></i>
                    &nbsp; Contacts Information. If you permit the STAR Cineplex  app to access the address book on your device via the permission system used by your platform, we may access and store names and contact information from your address book to facilitate social interactions through our Services and for other purposes described in this Statement or at the time of consent or collection.
                </li>

                <li><i className="fa fa-angle-right"></i>
                    &nbsp; Transaction Information: We collect transaction details related to the movie tickets or concession items (or other) services you provide through our platform, including the type of service provided, date and time the service was provided, amount charged, and other related transaction details. Additionally, if someone uses your promo code, we may associate your name with that person.
                </li>

                <li><i className="fa fa-angle-right"></i>
                    &nbsp; Usage and Preference Information. We collect information about how you and site visitors interact with our Services, preferences expressed, and settings chosen. In some cases we do this through the use of cookies, pixel tags, and similar device identification technologies that create and maintain unique identifiers. To learn more about these technologies, please see our Cookie Statement.
                </li>

                <li><i className="fa fa-angle-right"></i>
                    &nbsp; Device Information. We may collect information about your mobile device, including, for example, the hardware model, operating system and version, software and file names and versions, preferred language, unique device identifier, advertising identifiers, serial number, device motion information, and mobile network information.
                </li>

                <li><i className="fa fa-angle-right"></i>
                    &nbsp; Call and SMS Data. Our Services facilitate communications between Users and our Customer Service Agent. In connection with facilitating this service, we receive call data, including the date and time of the call or SMS message, the parties’ phone numbers, and the content of the SMS message.
                </li>

                <li><i className="fa fa-angle-right"></i>
                    &nbsp; Log Information. When you interact with the Services, we collect server logs, which may include information like device IP address, access dates and times, app features or pages viewed, app crashes and other system activity, type of browser, and the third-party site or service you were using before interacting with our Services. Any other personal information which you give us in connection with the Services.
                </li>

                <li><i className="fa fa-angle-right"></i>
                    &nbsp; Non-Identifiable Data. When you interact with STAR Cineplex through the Services, we receive and store certain information which does not identify you personally. Such information is collected passively using various technologies.
                </li>
            </ul>
                <p></p>
                <p>
                    Wherever STAR Cineplex collects Personal Data we make an effort to provide a link to this Privacy Policy.
                </p>
                <p>
                    By voluntarily providing us with Personal Data, you are consenting to our use of it in connection with the Services and in accordance with this Privacy Policy.
                </p>
                <p>
                    STAR Cineplex may store such information itself or such information may be included in databases owned and maintained by STAR Cineplex ’s affiliates, agents or service providers. The Services may use such information and pool it with other information on an anonymized and generalized basis to track, for example, the total number of users of our Services, the number of visitors to each page of our Site and the domain names of our visitors’ Internet service providers. It is important to note that no Personal Data is available or used in this process.
                </p>
            </div>
            <p>&nbsp;</p>
            <div className="bs-callout bs-callout">
                <h4>Use of Information</h4>
                <p>&nbsp;</p>
                <p>
                    By providing us with the information about you discussed above, you consent for us and our subsidiaries and affiliates (the “STAR Cineplex Related Companies”) to use that information in the following ways:
                </p><ul className="">
                <li><i className="fa fa-angle-right"></i>
                    &nbsp; To maintain, and improve our Services, including, for example, to facilitate payments, send receipts, provide products and services you request (and send related information), develop new features, provide customer support to Users and Workers, develop safety features, authenticate users, and send product updates and administrative messages.
                </li>

                <li><i className="fa fa-angle-right"></i>
                    &nbsp; To perform internal operations, including, for example, to prevent fraud and abuse of our Services; to troubleshoot software bugs and operational problems; to conduct data analysis, testing, and research; and to monitor and analyze usage and activity trends.
                </li>

                <li><i className="fa fa-angle-right"></i>
                    &nbsp; To implement and monitor any STAR Cineplex bookings which you make using our Services;
                </li>

                <li><i className="fa fa-angle-right"></i>
                    &nbsp; To share your Personal Data with STAR Cineplex professionals in order to carry out your STAR Cineplex bookings using our Services;
                </li>

                <li><i className="fa fa-angle-right"></i>
                    &nbsp; To ensure that content from our Services is presented in the most effective manner for you and for your computer or other device from which you access the Services;
                </li>

                <li><i className="fa fa-angle-right"></i>
                    &nbsp; To provide you with information, products or services that you request from us or which we feel may interest you;
                </li>

                <li><i className="fa fa-angle-right"></i>
                    &nbsp; To carry out our obligations arising from any contracts between you and us;
                </li>

                <li><i className="fa fa-angle-right"></i>
                    &nbsp; To allow you to participate in interactive features of our Services, when you choose to do so;
                </li>

                <li><i className="fa fa-angle-right"></i>
                    &nbsp; To notify you about changes to our Services;
                </li>

                <li><i className="fa fa-angle-right"></i>
                    &nbsp; To improve or modify the Services, for example based on how you use our Services;
                </li>

                <li><i className="fa fa-angle-right"></i>
                    &nbsp; To calculate conversion rates and other elements of Services’ performance; and
                </li>

                <li><i className="fa fa-angle-right"></i>
                    &nbsp; For marketing purposes (which we discuss further below).
                </li>

                <li><i className="fa fa-angle-right"></i>
                    &nbsp; To personalize and improve the Services, including to provide or recommend features, content, social connections, referrals, and advertisements.
                </li>
            </ul>
                <p></p>
            </div>
            <p>&nbsp;</p>
            <div className="bs-callout bs-callout">
                <h4>Sharing of Information</h4>
                <p>&nbsp;</p>
                <p>
                    STAR Cineplex is not in the business of selling your information. We consider this information to be a vital part of our relationship with you. There are, however, certain circumstances in which we may share your Personal Data with certain third parties, as set out below:
                </p><ul>
                <li><i className="fa fa-angle-right"></i>
                    &nbsp; With other people, as directed by you, in connection with a particular service, offering or promotion; or with third parties to provide you a service you requested through a partnership or promotional offering made by a third party or us.
                </li>

                <li><i className="fa fa-angle-right"></i>
                    &nbsp; With Workers to enable them to provide the Services you request.
                </li>

                <li><i className="fa fa-angle-right"></i>
                    &nbsp; To implement and monitor any STAR Cineplex bookings which you make using our Services;
                </li>

                <li><i className="fa fa-angle-right"></i>
                    &nbsp; With the general public if you submit content in a public forum, such as blog comments, social media posts, or other features of our Services that are viewable by the general public.
                </li>

                <li><i className="fa fa-angle-right"></i>
                    &nbsp; With third parties with whom you choose to let us share information, for example other apps or websites that integrate with our API or Services, or those with an API or Service with which we integrate.
                </li>

                <li><i className="fa fa-angle-right"></i>
                    &nbsp; With third parties to provide you a service you requested through a partnership or promotional offering made by a third party or us.
                </li>

                <li><i className="fa fa-angle-right"></i>
                    &nbsp; Business Transfers: As we develop our business, we might sell or buy businesses or assets. In the event of a corporate sale, merger, reorganization, dissolution or similar event, Personal Data may be part of the transferred assets.
                </li>

                <li><i className="fa fa-angle-right"></i>
                    &nbsp; Related Companies: We may also share your Personal Data with the STAR Cineplex Related Companies if we need to do so to fulfil this Privacy Policy.
                </li>

                <li><i className="fa fa-angle-right"></i>
                    &nbsp; Agents, Consultants and Related Third Parties: STAR Cineplex, like many businesses, sometimes hires other companies to perform certain business-related functions. Examples of such functions include mailing information, maintaining databases and processing payments. When we employ another company to perform a function of this nature, we only provide them with the information that they need to perform their specific function and under the same standards and protections as in this privacy policy.
                </li>

                <li><i className="fa fa-angle-right"></i>
                    &nbsp; Legal Requirements: STAR Cineplex may disclose your Personal Data if required to do so by law or in the good faith belief that such action is necessary to (i) comply with a legal obligation, (ii) protect and defend the rights or property of STAR Cineplex, (iii) act in urgent circumstances to protect the personal safety of users of the Services or the public, or (iv) protect against legal liability.
                </li>

                <li><i className="fa fa-angle-right"></i>
                    &nbsp; Aggregated Personal Data: In an ongoing effort to better understand and serve the users of the Services, STAR Cineplex conducts research on its user demographics, interests and behavior based on the Personal Data and other information provided to us. This research will be compiled and analyzed on an aggregate basis, and STAR Cineplex may share this aggregate data with its affiliates, agents and business partners. This aggregate information does not identify you personally. STAR Cineplex may also disclose aggregated user statistics in order to describe our Services to current and prospective business partners, and to other third parties for other lawful purposes in fulfilment of this privacy policy.
                </li>

                <li><i className="fa fa-angle-right"></i>
                    &nbsp; Marketing and advertising: STAR Cineplex and its affiliates may use Personal Data to contact you in the future to tell you about services we believe will be of interest to you. If we do so, each communication we send you will contain instructions permitting you to “opt-out” of receiving future communications. In addition, if at any time you wish not to receive any future communications or you wish to have your name deleted from our mailing lists, please contact us as indicated below.
                </li>

                <li><i className="fa fa-angle-right"></i>
                    &nbsp; The Services may integrate with social sharing features and other related tools which let you share actions you take on our Services with other apps, sites, or media, and vice versa. Your use of such features enables the sharing of information with your friends or the public, depending on the settings you establish with the social sharing service. Please refer to the privacy policies of those social sharing services for more information about how they handle the data you provide to or share through them.
                </li>
                <li>
                    With law enforcement officials, government authorities, or other third parties if we believe your actions are inconsistent with our user agreements or policies, or to protect the rights, property, or safety of STAR Cineplex.
                </li>

                <li>
                    In connection with, or during negotiations of, any merger, sale of company assets, consolidation or restructuring, financing, or acquisition of all or a portion of our business by or into another company.
                </li>
            </ul>
                <p></p>
                <p>
                    We do not disclose personal information about identifiable individuals to advertisers, but we may provide them with aggregate and/or anonymized information about our users to help advertisers reach the kind of audience they want to target. We may make use of the information we have collected from you to enable us to comply with our advertisers’ wishes by displaying their advertisement to that target audience.
                </p>
            </div>
            <p>&nbsp;</p>
            <div className="bs-callout bs-callout">
                <h4>Your Choices:</h4>
                <p>&nbsp;</p>
                <p>
                </p><ul>
                <li><i className="fa fa-angle-right"></i>
                    &nbsp; You can visit the Services without providing any Personal Data. If you choose not to provide any Personal Data, you may not be able to use certain STAR Cineplex Services.
                </li>

                <li><i className="fa fa-angle-right"></i>
                    &nbsp; Account Details. You may correct your account information at any time by logging into your online or in-app account. If you wish to cancel your account, please email us at <a href="mailto:customerservices@cineplexbd.com" target="_top" style={{color: '#b945ff'}}>customerservices@cineplexbd.com</a>. Please note that in some cases we may retain certain information about you as required by law, or for legitimate business purposes to the extent permitted by law.
                </li>

                <li><i className="fa fa-angle-right"></i>
                    &nbsp; Access Rights, STAR Cineplex will comply with individual’s requests regarding access, correction, and/or deletion of the personal data it stores in accordance with applicable law.
                </li>

                <li><i className="fa fa-angle-right"></i>
                    &nbsp; Location Details, we request permission for our app’s collection of precise location from your device as per the permission system used by your mobile operating system. If you initially permit the collection of this information, you can later disable it by changing the location settings on your mobile device. However, you may not be able to avail certain services on the STAR Cineplex App if you disable our collection of precise location data. Additionally, disabling our collection of precise location from your device will not limit our ability to derive approximate location from your IP address.
                </li>

                <li><i className="fa fa-angle-right"></i>
                    &nbsp; Contact Details, we may also seek permission for our app’s collection and syncing of contact information from your device as per the permission system used by your mobile operating system.
                </li>

                <li><i className="fa fa-angle-right"></i>
                    &nbsp; Contact Details, we may also seek permission for our app’s collection and syncing of contact information from your device as per the permission system used by your mobile operating system.
                </li>

                <li><i className="fa fa-angle-right"></i>
                    &nbsp; Promotions and News Alerts, you may opt out of receiving promotional messages from us by following the instructions in those messages. If you opt out, we may still send you non-promotional communications, such as those about your account, about Services you have requested, or our ongoing business relations.
                </li>


            </ul>
                <p></p>
            </div>
            <p>&nbsp;</p>
            <div className="bs-callout bs-callout">
                <h4>Links to Other Web Sites</h4>
                <p>&nbsp;</p>
                <p>
                    This Privacy Policy applies only to the Services. The Services may contain links to other web sites not operated or controlled by STAR Cineplex (the “Third Party Sites”). The policies and procedures we described here do not apply to the Third Party Sites. The links from the Services do not imply that STAR Cineplex endorses or has reviewed the Third Party Sites. We suggest contacting those sites directly for information on their privacy policies.
                </p>
            </div>
            <p>&nbsp;</p>
            <div className="bs-callout bs-callout">
                <h4>Security</h4>
                <p>&nbsp;</p>
                <p>
                    STAR Cineplex takes reasonable steps to protect the Personal Data provided via the Services from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. However, no Internet or e-mail transmission is ever fully secure or error free; any transmission is at your own risk. In particular, e-mail sent to or from the Services may not be secure. Therefore, you should take special care in deciding what information you send to us via e-mail. Please keep this in mind when disclosing any Personal Data to STAR Cineplex via the Internet. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorized access.
                </p>
                <p>
                    Registered STAR Cineplex users will have an account name and password which enables you to access certain parts of our Services. You are responsible for keeping them confidential. Please don’t share them with anyone.
                </p>
            </div>
            <p>&nbsp;</p>
            <div className="bs-callout bs-callout">
                <h4>Changes to STAR Cineplex ’s Privacy Policy</h4>
                <p>&nbsp;</p>
                <p>
                    The Services and our business may change from time to time. As a result, at times it may be necessary for STAR Cineplex to make changes to this Privacy Policy. Therefore, we may update this privacy policy from time to time (for example, to reflect changes in our business or the law). Any changes we may make will be posted on this page and we will highlight to you any material changes which we make. This Privacy Policy was last updated on the date indicated above. Your continued use of the Services after any changes or revisions to this Privacy Policy shall indicate your agreement with the revised terms.
                </p>
            </div>
            <p>&nbsp;</p>
            <div className="bs-callout bs-callout">
                <h4>Contacting STAR Cineplex</h4>
                <p>&nbsp;</p>
                <p>
                    To keep your Personal Data accurate, current, and complete, please contact us as specified below. We will take reasonable steps to update or correct Personal Data in our possession that you have previously submitted via the Services.
                </p>
                <p>
                    Please also feel free to contact us if you have any questions about STAR Cineplex ’s Privacy Policy or the information practices of the Services.
                </p>
                <p>
                    You may contact us as follows:
                </p>
                <p>
                    <strong>STAR Cineplex</strong><br />
                    Company: Show Motion Limited.<br />
                    Address: Level 8, Bashundhara City, 13/3 Ka, Panthapath, Tejgaon, Dhaka 1215, Bangladesh.<br />
                    Email: <a href="mailto:customerservices@cineplexbd.com" target="_top" style={{color: '#b945ff'}}>customerservices@cineplexbd.com</a>

                </p>
            </div >
        </div >
    )
}
