import React, {Component, useState} from 'react'
import OwlCarousel from 'react-owl-carousel';
import Image from './image';
import Video from './videos';


export default function gallery({galleryData} ){
    const[data, setData] = useState(JSON.parse(galleryData))
    return (
        <>
            {
                data.length ?
                    <>
                        <div className="hadding-area">
                            <h2 className="title">Movie Gallery</h2>
                        </div>
                        <OwlCarousel className="category-slide2"
                                     autoplay={
                                         true
                                     }
                                     autoplaySpeed={
                                         600
                                     }
                                     nav={
                                         true
                                     }
                                     dots={
                                         false
                                     }
                                     loop={
                                         true
                                     }
                                     margin={
                                         30
                                     }
                                     navText={
                                         ["<i class='flaticon-send'></i>", "<span class='flaticon-send'></span>"]
                                     }
                                     autoplayHoverPause={
                                         false
                                     }
                                     video={
                                         true
                                     }
                                     responsive={
                                         {
                                             0: {
                                                 items: 1
                                             }
                                             , 480: {
                                                 items: 2
                                             }
                                             , 568: {
                                                 items: 2
                                             }
                                             , 768: {
                                                 items: 2
                                             }
                                             ,
                                             992: {
                                                 items: 3
                                             }
                                             ,
                                             1024: {
                                                 items: 3
                                             }
                                             , 1200: {
                                                 items: 4
                                             }
                                         }
                                     }
                        >
                            {
                                data.length ?
                                    data.map((gallery, key) => {
                                        return (gallery.type == 1) ? <Image imageData={gallery} key={key}/> : <Video videoData={gallery} key={key}/>
                                    }) : ('')
                            }
                        </OwlCarousel>
                    </>
                    : ('')
            }
        </>
    )
}
