import moment from 'moment/moment'
import React, { useState, createContext } from 'react'
export const ShowTimeFilterContext = createContext()


export const ShowTimeFilterProvider = (props) => {
    const [dateRange, setDateRange] = useState([
        moment().format('YYYY-MM-DD'),
        moment().add(6,'days').format('YYYY-MM-DD')
    ])

    return (
        <ShowTimeFilterContext.Provider value={{dateRange, setDateRange}}>
            {props.children}
        </ShowTimeFilterContext.Provider>
    )
}