import React from "react";
import {Link} from 'react-router-dom'

export default function blogLG({blog}){
    return (
        <>
            <div className="movie-item-contents gradient5">
                <Link to={`/news/${blog.id}`}>
                    <img src={blog.image} alt=""></img>
                    <div className="movie-item-content">
                        <div className="movie-item-content-buttom">
                            <div className="movie-item-title">
                                <h3>{blog.title}</h3>
                                <small>{blog.time}</small>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </>
    )
}
