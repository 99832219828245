import React, {useEffect, useState} from "react";
import {apiCall} from "../../../api/apiCall";
import {CONCESSION_LIST} from "../../../api/apiRoutes";


export default function (){
    const [concessionList, setConcessionList] = useState([])

    const getConcessionList = async () => {
        const result = await apiCall(CONCESSION_LIST)
        setConcessionList(result)
    }
    useEffect(() => {
        getConcessionList()
    },[])
    return (
        <>
            <div className="details-contents">
                <div className="details-content">
                    <div className="details-dectiontion">
                        <h2 className="title pt-30">STAR Refreshments</h2>
                        <p>The movies just wouldn't be the same without an ice-cold,
                            refreshing pepsi and freshly popped popcorn. Now, classics
                            like Pop Chicken and candy are joined with more menu options
                            than ever.</p>
                        <ul className="media_list">
                            {
                                concessionList.length ?
                                    concessionList.map((data, key) => (
                                        <li className="row" key={key}>
                                            <img className="col-lg-4 col-xs-6" alt="Burger Combo" src={data.image} />
                                            <div className="col-lg-8 col-xs-6 media-text-body">
                                                <h3>{data.name}</h3>
                                                <div className="content" dangerouslySetInnerHTML={{__html: data.detail}}></div>
                                                {/*<p >*/}
                                                {/*    {data.detail}*/}
                                                {/*</p>*/}
                                            </div>
                                        </li>
                                    ))
                                    :
                                    null
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
