import React from "react";
import {Link} from 'react-router-dom'
import Image from "react-graceful-image";

export default function blogXS({blog}){
    return (
        <>
            <div className="single-item">
                <div className="img">
                    {/*<img src={blog.image} alt="" style={{ height: '61px', width: '61px' }} />*/}
                    <Image
                        src={blog.image}
                        alt={blog.title}
                        style={{ height: '61px', width: '61px' }}
                        retry={{ count: 10, delay: 2 }}
                        placeholderColor="#5f1a89"
                    />
                </div>
                <div className="widget-slide-content">
                    <Link to={`/news/${blog.id}`} className="title">{blog.title}</Link>
                    <span>{blog.time}</span>
                    <Link to={`/news/${blog.id}`} className="comments"><span className="fa fa-comments"></span> {blog.comment} comment's</Link>
                </div>
            </div>
        </>
    )
}
