import React, {useEffect, useState} from 'react'
import {apiCall} from "../api/apiCall";
import {GALLERY_LIST} from "../api/apiRoutes";
import $ from 'jquery'

export default function (){
    const [galleryList, setGalleryList] = useState([])
    const getGalleryData =  async () => {
        setGalleryList(await apiCall(GALLERY_LIST,{}))
    }

    useEffect(() => {
        getGalleryData()
            setTimeout(() => {
                $("[rel^='lightbox']").prettyPhoto()
            },1000)
    },[])

    return (
        <div>
            <div className="inner-page">
                <div className="faq-page pt-75 pb-0">
                    <div className="blog-page">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="hadding-area">
                                        <h2>Event / Photo Galleries</h2>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <aside className="col-xs-12">
                                    <div className="row">
                                        <div className="products">
                                            <div className="products gallery-list">
                                                {
                                                    galleryList.length ?
                                                        galleryList.map((galleryVal, key) => {
                                                            return <div className="col-md-3 col-sm-6 col-xs-12" key={key}>
                                                                <div
                                                                    className="movie-item-contents gallery-contetn gradient5">
                                                                    <img src={galleryVal.image} />
                                                                    <div className="gallery-hover">
                                                                        <a rel="lightbox[group]" href={galleryVal.image}><span className="fa fa-search"></span></a>
                                                                    </div>
                                                                    <div className="movie-item-content">
                                                                        <div className="movie-item-content-center">
                                                                        </div>
                                                                        <div className="movie-item-content-buttom">
                                                                            <div className="movie-item-title">
                                                                                <a href={galleryVal.image}>{galleryVal.title}</a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }) : ('')
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
