import React, {useEffect, useState} from 'react'
import {useParams} from "react-router-dom";
import {apiCall} from "../api/apiCall";
import MainBanner from './components/singleMovie/mainBanner';
import MovieDetail from './components/singleMovie/movieDetail';
import {connect} from "react-redux";

function movieDetail({locationData}){
    const params = useParams()
    const[movieDetail, setMovieDetail] = useState(null)
    const getMovieDetail = async () =>{
        setMovieDetail(await apiCall('/movie/' + params.id + '/detail',{
            location: locationData?.id
        }))
    }

    useEffect(() => {
        getMovieDetail()
    },[locationData.id, params.id])
    return (
        <>
            {
                movieDetail ?
                    <React.Fragment>
                        <MainBanner coverImage={movieDetail.cover_image} />
                        <MovieDetail movieDetail={movieDetail} />
                    </React.Fragment>
                    : ('')
            }
        </>
    )
}

const mapStateToProps = function(state) {
    return {
        locationData: state.location
    }
}

export default connect(mapStateToProps)(movieDetail)
