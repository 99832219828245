import React, { useState, useEffect } from 'react'
import { MOVIE_LIST } from '../api/apiRoutes'
import Slider from './components/home/mianSlider'
import FeatureList from './components/home/featuredList'
import LatestNews from './components/home/latestNews'
import { apiCall } from '../api/apiCall'
import {connect} from "react-redux";

function homeComponent(props) {
    const [movieListRunning, setMovieListRunning] = useState([])
    const [movieListUpComing, setMovieListUpComing] = useState([])

    const getMovieList = async () => {
        const moviesList = await apiCall(MOVIE_LIST, {
            'location': props.location?.id,
            'path': 'home'
        })
        setMovieListRunning(moviesList ? moviesList?.running : [])
        setMovieListUpComing(moviesList ? moviesList?.upcoming : [])
    }
    useEffect(() => {
        getMovieList()
    }, [props.location])

    return (
        <>
            <Slider />
            <div className="container-fluid social-visit text-center sm-none" style={{backgroundColor:'#210035'}}>
                <a href="https://facebook.com/MYCINEPLEX/"
                    className="movie-header__cta-button btn btn-button button-1 white-outline exit" target="_blank"> <span
                        className="btn--left"> <i className="fa fa-facebook"></i> Visit Facebook </span><span
                            className="btn--right"><i className="fa fa-chevron-right"></i></span>
                </a>
                <a href="https://www.youtube.com/channel/UCgYEyIGj0WlQGZY_Wxg6zYg"
                    className="movie-header__cta-button btn btn-button button-1 white-outline exit" target="_blank"> <span
                        className="btn--left"> <i className="fa fa-youtube"></i> Visit Youtube </span><span
                            className="btn--right"><i className="fa fa-chevron-right"></i></span>
                </a>
                <a href="https://www.instagram.com/mystarcineplex/"
                    className="movie-header__cta-button btn btn-button button-1 white-outline exit" target="_blank"> <span
                        className="btn--left"> <i className="fa fa-instagram"></i> Visit Instagram </span><span
                            className="btn--right"><i className="fa fa-chevron-right"></i></span>
                </a>
            </div>
            <FeatureList featureListRunning={movieListRunning} featureListUpcomming={movieListUpComing} />
            <LatestNews />
        </>
    )
}

const mapStateToProps = function(state) {
    return {
        token: state.token,
        user_id: state.user_id,
        location: state.location
    }
}

export default connect(mapStateToProps)(homeComponent);
