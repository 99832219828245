import React from 'react'
import {basePath} from '../../config/configFile'
import Close from '../../images/close.svg'
import Encard02 from '../../images/entertainment-card02.png'
import Encard01 from '../../images/entertainment-card.png'

export default function purchaseModal() {
    return (
        <>
            <div className="modal fade location_modal" id="buy_ticket_modal" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" >
                                <img src={Close} alt="" />
                            </button>
                            <h4 className="modal-title text-center">Buy Tickets</h4>
                        </div>
                        <div className="modal-body location-modal-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="ticket_list text-center">
                                        <ul className="row d-table">
                                            <li className="col-md-6 mb-2">
                                                <div className="ticket_list_item">
                                                    <img src={Encard02} alt="" />
                                                    <h3 style={{
                                                        margin: '20px 0px',
                                                        fontWeight: 400
                                                    }}>PURCHASE A TICKET </h3>
                                                    <h4>Through Card/Mobile Banking/Internet Banking</h4>
                                                    <a href={basePath.ticketBase} target="_blank" rel="noopener noreferrer" className="btn btn-button red-bg button-1">Buy Now</a>
                                                </div>
                                            </li>
                                            <li className="col-md-6 mb-2">
                                                <div className="ticket_list_item">
                                                    <img src={Encard01} alt="" />
                                                    <h3 style={{
                                                        margin: '20px 0px',
                                                        fontWeight: 400
                                                    }}>PURCHASE A TICKET</h3>
                                                    <h4>Through Entertainment Card</h4>
                                                    <a href="#" onClick={(e) => e.preventDefault()} target="_blank" rel="noopener noreferrer" className="btn btn-button red-bg button-1">Buy Now</a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}