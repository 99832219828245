import React, { Component, useEffect, useState } from "react";
import Details from "./components/contact/details";
// import Form from "./components/contact/form";
import { apiCall } from "../api/apiCall";
import { GET_LOCATION } from "../api/apiRoutes";

export default function () {
  const [location, setLocation] = useState([]);

  const getLocation = async () => {
    setLocation((await apiCall(GET_LOCATION, {})) ?? []);
  };

  useEffect(() => {
    getLocation();
  }, []);
  return (
    <div>
      <div className="inner-page">
        <div className="contact-page pt-40 pb-75">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-8">
                    <div className="">
                      <Details />
                      {/* <Form /> */}
                    </div>
                  </div>
                  <div className="col-md-4">
                    {location
                      ? location.map((theater) => {
                          {
                            var map_location =
                              "https://maps.google.com/maps?q=" +
                              theater.location_name +
                              "&t=&z=13&ie=UTF8&iwloc=&output=embed";
                          }
                          return (
                            <React.Fragment key={theater.id}>
                              <div className="widget mb-50">
                                <div className="widget-title">
                                  <i className="fa fa-list"></i>
                                  <span>{theater.location_name}</span>
                                </div>
                                <div className="widget-content">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: theater.location_detail,
                                    }}
                                  ></div>
                                </div>
                              </div>
                              <div className="gmap mb-50">
                                <iframe
                                  title="cineplex_location"
                                  width="100%"
                                  height="275"
                                  frameBorder="0"
                                  style={{ border: "0" }}
                                  allowFullScreen=""
                                  src={map_location}
                                ></iframe>
                              </div>
                            </React.Fragment>
                          );
                        })
                      : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
