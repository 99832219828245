import React, { useEffect, useState } from 'react'
import {apiCall} from '../../../api/apiCall'
import {NOTICE} from '../../../api/apiRoutes'

export default function notice() {
    const [notice, setNotice] = useState(null)

    const getNotice = async () => {
        var noticeData = await apiCall(NOTICE,{})
        setNotice(noticeData?.notice)
    }

    useEffect(() => {
        getNotice()
    }, [])

    return (
        <>
            <div className="widget special-widget mb-10">
                <div className="widget-title special-widget-title">
                    <i className="fa fa-list"></i><span>Notice</span>
                </div>
                <div className="widget-content notice-app">
                    <p>{notice ? notice : null}</p>
                </div>
            </div>
        </>
    )
}
