import Cookies from 'universal-cookie';

const cookies = new Cookies();

const initialState = {
    headerLoad: false,
    token: cookies.get('token') ?? null,
    user_id: cookies.get('user_id') ?? null,
    location: localStorage.getItem('theater') ? JSON.parse(localStorage.getItem('theater')) : null
}

const mainReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_TOKEN':
            return {
                ...state,
                ...action.payload
            };
        case 'SET_LOCATION':
            return {
                ...state,
                location: action.payload
            };
        case 'HEADER_LOAD':
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}

export default mainReducer
