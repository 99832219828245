import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import Route from "./route/route";
import ReactGA from "react-ga4";
ReactGA.initialize(process.env.REACT_APP_G_MEASUREMENT_ID);

import "bootstrap/dist/css/bootstrap.css";
import "./styles/bootstrap-select.min.css";
import "./styles/owl.carousel.css";
import "./styles/font-awesome.min.css";
import "./styles/flaticon.css";
import "./styles/icofont.css";
import "./styles/animate.css";
import "./styles/prettyPhoto.css";
import "./styles/video.popup.css";
import "./styles/jquery.lighter.css";
import "./styles/bootsnav.css";
import "./styles/bootstrap-touch-slider.css";
import "./styles/style.css";
import "./styles/media.css";
import "./styles/custom-reza.css";
import "./styles/custom-saikat.css";
import "./styles/custom-sabbir.css";
import "./styles/custom-nisha.css";

function App() {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  ReactGA.event({
    category: "User",
    action: "Visited the page",
    label: window.location.pathname,
  });
  return (
    <div className="App">
      <Provider store={store}>
        <Route />
      </Provider>
    </div>
  );
}

export default App;
