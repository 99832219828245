import React from "react";
import { connect } from "react-redux";
import alertIcon from "../../../images/altert.svg";

function head({ locationData }) {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="hadding-area">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h2>
                  Weekly Showtime{" "}
                  <span>
                    <br />[{" "}
                    {locationData
                      ? locationData.location_name
                      : "No Location Selected"}{" "}
                    ]
                  </span>{" "}
                </h2>
                {locationData?.notice && (
                  <span className="notice">
                    <img src={alertIcon} alt="alert" />
                    {locationData?.notice}
                  </span>
                )}
                <h2>
                  <small
                    data-toggle="modal"
                    data-target="#loc_cng_modal"
                    className="red-bg button-1"
                  >
                    Change Location
                  </small>
                </h2>
              </div>
              <div className="seat-types pull-right">
                <p className="btn btn-xs btn-vip">*</p>
                <span className="">STAR VIP</span>
                <p className="btn btn-xs btn-premium">*</p>
                <span className="">STAR PREMIUM</span>
                <p className="btn btn-xs btn-general">*</p>
                <span className="mr-0">OTHERS HALL</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = function (state) {
  return {
    locationData: state.location,
  };
};

export default connect(mapStateToProps)(head);
