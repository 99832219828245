import React from "react";
import Lottie from 'react-lottie';
import MainLoader from './../../images/mainLoader.json'

export default function Loader(){
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: MainLoader,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <div className="col-md-12 text-center" style={{opacity:0.4}}>
            <Lottie
                options={defaultOptions}
                height={window.screen.height * 0.28}
                width={window.screen.height * 0.28}
            />
        </div>
    )
}