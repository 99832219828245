import React, {useEffect, useState} from 'react'
import swal from 'sweetalert';
import {POST_COMMENT} from '../../../api/apiRoutes'
import {apiCall, postCallWithresponse} from "../../../api/apiCall";
import {loadReCaptcha, ReCaptcha} from "react-recaptcha-v3";
import {store} from "../../../redux/store";

export default function commentForm({blogId}){
    const state = store.getState()
    const [formData, setFormData] = useState({
        blog_id: blogId,name: '',email: '', comment:'',recaptchaToken: ''
    })

    const verifyCallback = (data) => {
        setFormData({
            ...formData,
            recaptchaToken: data
        })
    }

    const handleChange = (e) => {
        e.preventDefault()
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const formSubmit = async (e) => {
        e.preventDefault()
        const result = await postCallWithresponse(POST_COMMENT, {...formData,  user_id: state.user_id}, {})
        if(result.code === 200){
            swal('Success:', result.message, 'success');
            setFormData({
                name: '',email: '', comment:'',recaptchaToken: ''
            })
        } else {
            swal('Error:', result.message, 'error');
        }
    }

    useEffect(() => {
        loadReCaptcha('6Lddr8QUAAAAABh9QxGA9GxgEQzwBA4Y1NObXEcH');
        setFormData({
            blog_id: blogId,name: '',email: '', comment:'',recaptchaToken: '6Lddr8QUAAAAABh9QxGA9GxgEQzwBA4Y1NObXEcH'
        })
    },[blogId])

    return (
        <>
            <div className="comment-respond">
                <h2 className="title">Leave a Comment</h2>
                <div className="respons-box">
                    <div className="form">
                        <form onSubmit={formSubmit}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="name">Nick Name :</label>
                                        <input id="name" className="form-control form-mane" name="name" onChange={handleChange} value={formData.name} placeholder="Name" required={true} type="text" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="email">E-mail :</label>
                                        <input id="email" className="form-control form-email" name="email" onChange={handleChange} value={formData.email} placeholder="email" type="email" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="message">Write a Message :</label>
                                <textarea id="message" className="form-control form-comment" cols="10" rows="8" name="comment" name="comment" onChange={handleChange} value={formData.comment} required={true}></textarea>
                            </div>
                            <ReCaptcha
                                sitekey="6Lddr8QUAAAAABh9QxGA9GxgEQzwBA4Y1NObXEcH"
                                action='homepage'
                                verifyCallback={verifyCallback}
                            />
                            <div className="buttons">
                                <button type="submit" className="btn btn-buttons">Comment</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
