import React from "react";
import {Link} from "react-router-dom";
import {basePath} from "../../config/configFile";

export default function movie({movieDetail, handleVideoPlay,isRunning}){
    return (
        <>
            <div className="col-md-3 col-sm-6 col-xs-12">
                <div className="movie-item-contents gradient5">
                    <img src={movieDetail.img} />
                    <div className="movie-item-content">
                        <div className="movie-item-content-center">
                            {
                                movieDetail.video ?
                                    <a href='#' onClick={(e) => handleVideoPlay(e, movieDetail.video)} className="flat-icons video-pop" data-video-url={movieDetail.video}
                                       style={{ cursor: 'pointer' }}><span className="flaticon-play-button"></span></a>
                                    :
                                    <a href='#' onClick={e => e.preventDefault()} className="flat-icons" data-video-url={movieDetail.video}
                                       style={{ cursor: 'pointer' }}><span className="flaticon-play-button"></span></a>
                            }
                        </div>
                        <div className="movie-item-content-buttom">
                            <div className="movie-item-title">
                                <Link to={`/detail/${movieDetail.id}`}>{movieDetail.title}</Link>
                            </div>
                            <div className="item-cat">
                                <div className="item-cat-hover">
                                    <ul>
                                        <li><span>Release :</span><a href="">{movieDetail.release}</a></li>
                                        <li><span>Genre :</span><a href="">{movieDetail.genre}</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="movie-item-beta">
                                {
                                    isRunning ?
                                        // <div className="movie-details">
                                        //     <a href="#" data-toggle="modal" data-target="#buy_ticket_modal"
                                        //        className="btn btn-button button-detals red-bg">Get Tickets</a>
                                        // </div>
                                        <div className="movie-details">
                                            <a href={basePath.ticketBase} target="_blank"
                                               className="btn btn-button button-detals red-bg">Get Tickets</a>
                                        </div>
                                        : null
                                }
                                <div className="view-movie hover-right">
                                    <Link className="btn btn-button button-details red-bg" to={`/detail/${movieDetail.id}`}>Details</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
