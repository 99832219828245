import React, { Component } from 'react'

export default class details extends Component {
    render() {
        return (
            <div className="contact-details">
                <div className="hadding-area contact-hadding-area mt-0">
                    <h3>Hot Line</h3>
                </div>
                <p> Cell: 01755665544</p>
                <div className="clearfix"></div>
                <div className="hadding-area contact-hadding-area">
                    <h3>Call Us</h3>
                </div>
                <p> Tel: (+88) 09617660660 </p>
                <div className="clearfix"></div>
                <div className="hadding-area contact-hadding-area">
                    <h3>Email Us</h3>
                </div>
                <p><span>Customer Service:</span> <a href="mailto:customerservices@cineplexbd.com">customerservices@cineplexbd.com</a> </p>
                <p><span>General Information:</span> <a href="mailto:info@cineplexbd.com">info@cineplexbd.com</a></p>
                <p><span>Marketing Department:</span> <a href="mailto:media_mkt@cineplexbd.com">media_mkt@cineplexbd.com</a>, <a href="rebecca@cineplexbd.com">rebecca@cineplexbd.com</a>, <a href="himel@cineplexbd.com">himel@cineplexbd.com</a></p>
                <p><span>Membership Coordinator:</span> <a href="mailto:member@cineplexbd.com">member@cineplexbd.com</a></p>
                <p><span>Hr/Admin Department:</span> <a href="mailto:hr@cineplexbd.com m">hr@cineplexbd.com </a></p>
                <p><span>Executive Director:</span> <a href="mailto:ed@cineplexbd.com">ed@cineplexbd.com</a></p>
                <p><span>Managing Director:</span> <a href="mailto:mrahman@cineplexbd.com">mrahman@cineplexbd.com</a></p>
            </div>
        )
    }
}
