import React, { useEffect, useState } from 'react'
import { POLL_MOVIE_LIST, VOTE_POLL } from "../../../api/apiRoutes";
import { apiCall, postCallWithresponse } from "../../../api/apiCall";
import $ from 'jquery'
import {store} from "../../../redux/store";
import swal from 'sweetalert'
import {loadReCaptcha, ReCaptcha} from "react-recaptcha-v3";

export default function poll() {
    const state = store.getState()
    const [pollMovie, setPollMovie] = useState(null)
    const [recaptchaToken,setRecaptchaToken] = useState('')
    const getMovie = async () => {
        var movie = await apiCall(POLL_MOVIE_LIST, {})
        setPollMovie(movie)
    }

    const verifyCallback = (data) => {
        setRecaptchaToken(data)
    }

    /**
     * @param {{ preventDefault: () => void; }} e
     */
    const pollSubmit = async (e) => {
        e.preventDefault()
        const rating = $("input[name=poll]:checked").val()
        if (rating){
            const result = await postCallWithresponse(VOTE_POLL, {
                user_id: state.user_id,
                movie_id: pollMovie.id,
                rating: rating,
                recaptchaToken: recaptchaToken
            })
            setTimeout(() => {
                $("input:radio[name=poll]:checked")[0].checked = false
            },500)
            if(result?.code === "200" || result?.code === 200){
                swal('Success:', result?.message, 'success');
            } else if(result?.code === "401" || result?.code === 401){
                swal('Success:', 'Your session expired please reload and submit again', 'error');
            } else {
                swal('Error:', result?.message, 'error');
            }
        }
    }

    useEffect(() => {
        // loadReCaptcha('6Lddr8QUAAAAABh9QxGA9GxgEQzwBA4Y1NObXEcH');
        getMovie()
    }, [])

    return (
        <>
            <div className="widget mb-10">
                <div className="widget-title">
                    <i className="fa fa-list"></i><span>Poll</span>
                </div>
                {
                    pollMovie ?
                        <div className="widget-content">
                            <form id="poll_form" onSubmit={pollSubmit} method="post">
                                <p>{pollMovie.text}</p>
                                {/*<div className="bs-example" onChange={handleRating}>*/}
                                <div className="bs-example">
                                    <label htmlFor="1" className="radio-inline">
                                        <input type="radio" id="1" value="1" name="poll" />Good </label>
                                    <label htmlFor="2" className="radio-inline">
                                        <input type="radio" id="2" value="2" name="poll" />Average </label>
                                    <label htmlFor="3" className="radio-inline">
                                        <input type="radio" id="3" value="3" name="poll" />Excellent </label>
                                </div>
                                <div className="bs-example">
                                    <button type="submit" name="button" className="btn btn-button red-bg button-1 animation delay-04 poll-btn">Submit</button>
                                </div>
                            </form>
                            <ReCaptcha
                                sitekey="6Lddr8QUAAAAABh9QxGA9GxgEQzwBA4Y1NObXEcH"
                                action='homepage'
                                verifyCallback={verifyCallback}
                            />
                        </div>
                        : ''
                }
            </div>
        </>
    )
}
