import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import {apiCall} from "../../../api/apiCall";
import {GALLERY_LIST} from '../../../api/apiRoutes'
import Image from "react-graceful-image";

export default function rightAsideComponent() {
    const [gallery, setGallery] = useState([])

    const getGalleryData = async () =>{
        setGallery(await apiCall(GALLERY_LIST,{userPath: 'about'}) ?? [])
    }
    useEffect(() => {
        getGalleryData()
    },[1])
    return (
        <div>
            <aside className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                <div className="widget mb-50">
                    <div className="widget-title"><i className="fa fa-list"></i><span>Event / Photo Galleries</span>
                    </div>
                    <div className="widget-recent-post widget-event-post">
                        <div className="item text-center">
                            {
                                gallery.length ?
                                    gallery.map((image,key) => {
                                        return <div className="single-item" key={key}>
                                            <div className="img">
                                                {/*<img src={image.image} alt="" style={{height:"70px"}} />*/}
                                                <Image
                                                    src={image.image}
                                                    alt={image.title}
                                                    style={{height:"70px"}}
                                                    retry={{ count: 10, delay: 2 }}
                                                    placeholderColor="#5f1a89"
                                                />
                                            </div>
                                            <div className="widget-slide-content text-left">
                                                <Link to="/gallery" className="title">{image.title}</Link>
                                                <span>{image.time}</span>
                                            </div>
                                        </div>
                                    }) : ('')
                            }
                            <Link to="/gallery" className="btn btn-button btn-block red-bg button-1">See More Event Gallery...</Link>
                        </div>
                    </div>
                </div>
            </aside>
        </div>
    )
}


