import React, {useState} from 'react'

export default function cast({castData}){
    const[data, setData] = useState(JSON.parse(castData))
    return (
        <>
            {
                data.length ?
                    <React.Fragment>
                        <h2 className="title hadding-area">CAST</h2>
                        <ul className="mvcast-item">
                            {
                                data.map((cast,key) => {
                                    return <React.Fragment key={key}>
                                        {
                                            cast.url ?
                                                <a href={cast.url} target="_blank">
                                                    <li className="cast-it" >
                                                        <img src={cast.image} alt="" />
                                                        <p>{cast.name}</p>
                                                    </li>
                                                </a>
                                                :
                                                <li className="cast-it" >
                                                    <img src={cast.image} alt="" />
                                                    <p>{cast.name}</p>
                                                </li>
                                        }
                                    </React.Fragment>
                                })
                            }
                        </ul>
                    </React.Fragment>
                    : ''
            }
        </>
    )
}
