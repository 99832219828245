import React, {useEffect, useState} from "react";
import {Route} from 'react-router-dom'
import {connect} from "react-redux";

function protectedRoute({token, user_id, children,...rest}){
    const [isAuth, setIsAuth] = useState(false)

    useEffect(() => {
        if(token && user_id){
            setIsAuth(true)
        } else {
            setIsAuth(false)
        }
    },[])

    return (
        <Route {...rest} render={(props) => {
            if(isAuth){
                return children
            }
        }}/>
    )
}

const mapStateToProps = function(state) {
    return {
        token: state.token,
        user_id: state.user_id,
    }
}

export default connect(mapStateToProps)(protectedRoute);
