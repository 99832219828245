import React, {Component, useState} from 'react'
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { videoPopup } from '../../../js-files/Video/video.popup';

export default function videos({videoData}){
    useState(()=>{
        $(".video-pop").videoPopup({
                autoplay: 1, controlsColor: 'white',
                showVideoInformations: 0,
                width: 1000,
                customOptions: {
                    rel: 0,
                    end: 60
                }
            }
        );
    },[1])

    return (
        <div className="item">
            <div className="gallery-contetn gradient">
                <img src={videoData.image} alt="" style={{height:'250px'}}/>
                <div className="gallery-hover">
                    <Link to='#' data-video-url={videoData.url} className="video-pop">
                        <span className="fa fa-youtube-play"></span>
                    </Link>
                </div>
            </div>
        </div>
    )
}
