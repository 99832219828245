import React, {useEffect, useState} from 'react'
import Movie from '../../common/movie'
import ModalVideo from "react-modal-video";

export default function movies({movieRunning, movieUpcomming}){
    const [url, setUrl] = useState(null)

    const [isOpen, setIsOpen] = useState(false)

    const handleVideoPlay = (e,url) => {
        e.preventDefault();
        setIsOpen(true)
        var url_id = url.split('=')[1]
        setUrl(url_id)
    }
    return (
        <>
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId={url} onClose={() => setIsOpen(false)} />
            <div className="inner-page">
                <div className="full-withbanner">
                    <div className="banner-img zoom">
                        <div className="container">
                            <div className="full-withcon pt-75">
                                <div className="cat-menu">
                                    <ul className="nav nav-tabs tab-menu">
                                        <li className="active">
                                            <a data-toggle="tab" href="index.html#latestmovie"><span>Now Showing</span></a>
                                        </li>
                                        <li>
                                            <a data-toggle="tab" href="index.html#commingsoon"><span>Coming Soon</span></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <aside className="col-xs-12">
                            <div className="row">
                                <div className="products">
                                    <div id="latestmovie" className="tab-pane fade active in" role="tabpanel">
                                        <div className="products product-list mb-0 pt-40 pb-40">
                                            {
                                                movieRunning.length ? movieRunning.map((movie, key) => {
                                                    return <Movie movieDetail={movie} handleVideoPlay={handleVideoPlay} isRunning={true} key={key}/>
                                                }) : null
                                            }
                                        </div>
                                    </div>
                                    <div id="commingsoon" className="tab-pane fade" role="tabpanel">
                                        <div className="products product-list" >
                                            {
                                                movieUpcomming.length ? movieUpcomming.map((movie, key) => {
                                                    return <Movie movieDetail={movie} handleVideoPlay={handleVideoPlay} isRunning={false} key={key}/>
                                                }) : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </aside>
                    </div>
                </div>
            </div>
        </>
    )
}
