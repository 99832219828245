import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import {Link} from "react-router-dom";

export default function movieInfo({movieDetail,handleVideoPlay}){
    return (
        <>
            <div className="dec-review-dec">
                <div className="details-title">
                    <h2>{movieDetail.movie_title}</h2>
                </div>
                <div className="ratting">
                    <span className="fa fa-star"></span> {movieDetail.rating || 'N\\A'}
                </div>
                <div className="dec-review-meta">
                    <ul>
                        {
                            movieDetail.category ? <li><span>Category <label>: </label></span> {movieDetail.category}</li> : ''
                        }
                        <li><span className="mr-2">Actor <label>: </label></span> {movieDetail.actor}</li>
                        <li><span className="mr-2">Genre <label>: </label></span> {movieDetail.genre}</li>
                        {
                            movieDetail.release ? <li><span>Release <label>: </label></span> {movieDetail.release}</li> : ''
                        }
                        <li><span className="mr-2">Language <label>: </label></span> {movieDetail.language}</li>
                    </ul>
                </div>
                <div className="details-buttons">
                    {
                        (movieDetail.status == 1) ?
                            <AnchorLink  href="#movie_detail_show_time" className="btn btn-button red-bg button-1">Show Time</AnchorLink >
                            :''
                    }
                    <Link to="" onClick={(e) => handleVideoPlay(e, movieDetail.trailer_url)} className="flat-icons video-pop btn btn-button btn-border button-1 animation delay-04 mr-0" data-animation="animated fadeInRight">Watch Trailer</Link>
                </div>
            </div>
        </>
    )
}
