import React, { useEffect, useState, useContext } from 'react'
import AllShow from './components/showTime/allShowTimes';
import Head from './components/showTime/head';
import { apiCall } from "../api/apiCall";
import { MOVIE_SHOW_TIME } from "../api/apiRoutes";
import { connect } from "react-redux";
import { ShowTimeFilterContext } from '../context/showTimeFilterContext'

function showtime({ locationData }) {
    const [showTime, setShowTime] = useState([])
    const { dateRange } = useContext(ShowTimeFilterContext);
    const getShowTimeList = async () => {
        const showTimeData = await apiCall(MOVIE_SHOW_TIME, {
            location: locationData?.id,
            date_from: dateRange[0],
            date_to: dateRange[1]
        }) ?? []
        setShowTime(showTimeData)
    }

    useEffect(() => {
        getShowTimeList()
    }, [locationData, dateRange])

    return (
        <>
            <div className="inner-page">
                <div className="faq-page pb-0" style={{ paddingTop: '35px' }}>
                    <Head />
                    <AllShow showTimeData={showTime} />
                </div>
            </div>
        </>
    )
}

const mapStateToProps = function (state) {
    return {
        locationData: state.location
    }
}

export default connect(mapStateToProps)(showtime)
