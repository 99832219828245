import React from 'react'
import { Link } from 'react-router-dom';
import Image from 'react-graceful-image'
import {basePath} from "../../config/configFile";

export default function sliderMovie({ movie, handleVideoPlay, isRunning }) {
    return (
        <>
            <div className="item" key={movie.id}>
                <div className="movie-item-contents gradient5">
                    {/*<img src={movie.img} alt="" />*/}
                    <Image
                        src={movie.img}
                        alt={movie.title}
                        retry={{ count: 10, delay: 2 }}
                        placeholderColor="#5f1a89"
                    />
                    <div className="movie-item-content">
                        <div className="movie-item-content-center">
                            {
                                movie.video ?
                                    <a href='#' onClick={(e) => handleVideoPlay(e, movie.video)} className="flat-icons video-pop" data-video-url={movie.video}
                                        style={{ cursor: 'pointer' }}><span className="flaticon-play-button"></span></a>
                                    :
                                    <a href='#' onClick={e => e.preventDefault()} className="flat-icons" data-video-url={movie.video}
                                        style={{ cursor: 'pointer' }}><span className="flaticon-play-button"></span></a>
                            }
                        </div>
                        <div className="movie-item-content-buttom">
                            <div className="movie-item-title">
                                <a href="">{movie.title}</a>
                            </div>
                            <div className="item-cat">
                                <div className="item-cat-hover">
                                    <ul>
                                        <li><span>Release :</span><a href="">{movie.release}</a></li>
                                        {
                                            movie.genre ?
                                                <li><span>Genre :</span><a href="">{movie.genre}</a></li>
                                                : ''
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="movie-item-beta">
                                {
                                    isRunning ?
                                        // <div className="movie-details">
                                        //     <a href="#" data-toggle="modal" data-target="#buy_ticket_modal"
                                        //         className="btn btn-button button-detals red-bg">Get Tickets</a>
                                        // </div>
                                        <div className="movie-details">
                                            <a href={basePath.ticketBase}
                                               className="btn btn-button button-detals red-bg" target="_blank">Get Tickets
                                            </a>
                                        </div>
                                        : null
                                }
                                <div className="view-movie hover-right">
                                    <Link className="btn btn-button button-details red-bg"
                                        to={`/detail/${movie.id}`}>Details</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}