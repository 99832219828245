import React, { useState, useEffect } from 'react'
import Movies from './components/movieList/movies';
import {store} from '../redux/store'
import {apiCall} from "../api/apiCall";
import {MOVIE_LIST} from "../api/apiRoutes";
import {connect} from "react-redux";

function MovieList(props) {
    const state = store.getState()

    const [movieListRunning, setMovieListRunning] = useState([])
    const [movieListUpcomming, setMovieListUpcomming] = useState([])

    const getMovieList = async () => {
        const moviesList = await apiCall(MOVIE_LIST, {
            'location': state.location?.id
        })
        setMovieListRunning(moviesList.running)
        setMovieListUpcomming(moviesList.upcoming)
    }
    useEffect(() => {
        getMovieList()
    }, [props.locationData])

    return (
        <>
            <Movies movieRunning={(movieListRunning && movieListRunning.length) ? movieListRunning : []}
                    movieUpcomming={(movieListUpcomming && movieListUpcomming.length) ? movieListUpcomming : []}/>
        </>
    )
}

const mapStateToProps = function(state) {
    return {
        locationData: state.location,
    }
}

export default connect(mapStateToProps)(MovieList);