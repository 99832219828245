import React, { useEffect, useState } from 'react'
import { SLIDER_LIST } from '../../../api/apiRoutes'
import { apiCall } from '../../../api/apiCall'
import ModalVideo from 'react-modal-video'
import Loader from "../../common/loader";
import Image from "react-graceful-image";
import {basePath} from "../../../config/configFile";

export default function mianSlider() {
    const [list, setList] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const [url, setUrl] = useState(null)

    const sliderData = async () => {
        var sliderList = await apiCall(SLIDER_LIST, {});
        setList(sliderList)
    }

    const handleVideoPlay = (e,url) => {
        e.preventDefault();
        setIsOpen(true)
        var url_id = url.split('=')[1]
        setUrl(url_id)
    }

    useEffect(() => {
        sliderData()
    }, [])
    return (
        <>
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId={url} onClose={() => setIsOpen(false)} />
            <div className="slider-section carousel bs-slider fade control-round indicators-line" id="bootstrap-touch-slider" data-ride="carousel" data-interval="5000">
                <ol className="carousel-indicators">
                    {
                        (list && list.length) ?
                            list.map((slider, key) => {
                                return <li data-target="#bootstrap-touch-slider" data-slide-to={key} className={key == 0 ? "active" : ''} key={key}></li>
                            }) :
                            null
                    }
                </ol>
                <div className="slider-items carousel-inner" role="listbox">
                    {
                        (list && list.length) ?
                            list.map((slider) => {
                                return <div className={"item " + (slider.id == list[0].id ? 'active' : null)} key={slider.id}>
                                    <div className="slider-img">
                                        {/*<img src={slider.img} alt="" />*/}
                                        <Image
                                            src={slider.img}
                                            alt={slider.title}
                                            retry={{ count: 10, delay: 2 }}
                                            placeholderColor="#5f1a89"
                                        />
                                    </div>
                                    <div className="slider-contents">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-lg-9">
                                                    <div className="slider-content">
                                                        <h3 className="delay-03" data-animation="animated fadeInDown"> </h3>
                                                        {/* <h2 className="delay-04" data-animation="animated fadeInRight ">Be There When the  <span className="">King Returns</span></h2> */}
                                                        {
                                                            (slider.show_title == 1) ?
                                                                <h2 className="delay-04" data-animation="animated fadeInRight " >{slider.header}</h2>
                                                                : null
                                                        }
                                                        {
                                                            (slider.show_body == 1) ?
                                                                <p className="delay-06" data-animation="animated fadeInUp">{slider.body} </p>
                                                                : null
                                                        }
                                                        {
                                                            (slider.show_getTicket == 1) ?
                                                                // <a href='#' data-toggle="modal" data-target="#buy_ticket_modal" className="btn btn-button red-bg button-1 animation delay-04" data-animation="animated fadeInUp">Get Tickets</a>
                                                                <a href={basePath.ticketBase} className="btn btn-button red-bg button-1 animation delay-04" target="_blank">Get Tickets</a>
                                                                : null
                                                        }
                                                        {
                                                            (slider.show_trailer == 1) ?
                                                                <a href="#" onClick={(e) => handleVideoPlay(e,slider.video)} data-video-url={slider.video} className="flat-icons video-pop btn btn-button btn-border button-1 animation delay-04" data-animation="animated fadeInRight">Watch Trailer</a>
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }) :
                            <Loader/>
                    }
                </div>
                <a className="left carousel-control" href="#bootstrap-touch-slider" role="button" data-slide="prev">
                    <span className="flaticon-send" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="right carousel-control" href="#bootstrap-touch-slider" role="button" data-slide="next">
                    <span className="flaticon-send" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>
            </div>
        </>
    )
}
