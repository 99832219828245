import React, { useEffect, useState } from 'react'
import Notice from './notice';
import Poll from './poll';
import Vote from './vote';
import { Link } from 'react-router-dom';
import { BLOG_LIST } from '../../../api/apiRoutes'
import { apiCall } from '../../../api/apiCall';
import Image from "react-graceful-image";

export default function latestNews() {
    const [firstBlog, setFirstBlog] = useState(null)
    const [restBlog, setRestBlog] = useState([])
    const [secondBlog, setSecondBlog] = useState([])

    const getBlog = async () => {
        var blogList = await apiCall(BLOG_LIST, { 'url': 'home' })
        if (blogList && blogList?.length) {
            const s_value = [];
            const rest_value = [];
            blogList.map((data, key) => {
                if (key === 0) {
                    setFirstBlog(data)
                } if (key === 1 || key === 2) {
                    s_value.push(data)
                }
                if (key > 2) {
                    rest_value.push(data)
                }
            })
            setSecondBlog(s_value)
            setRestBlog(rest_value)
        }
    }

    useEffect(() => {
        getBlog()
    }, [])

    return (
        <>
            <section className="latest-news-section pt-50">
                <div className="category-movie-items">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9">
                                <div className="hadding-area d-flex align-items-center justify-content-between mb-2">
                                    <h2>Latest News</h2>
                                    <Link to="/news" className="pull-right btn btn-button button-1 white-outline view-all-news-1 hidden-sm hidden-xs" style={{ marginBottom: '0 ' }}>View All News</Link>
                                </div>
                                <div className="row pb-50 sm-mt-2">
                                    <div className="col-lg-8 col-sm-12 col-xs-12">
                                        {
                                            firstBlog ?
                                                <div className="movie-item-contents gradient5">
                                                    <Image
                                                        src={firstBlog.image}
                                                        alt={firstBlog.title}
                                                        retry={{ count: 10, delay: 2 }}
                                                        placeholderColor="#5f1a89"
                                                    />
                                                    <div className="movie-item-content">
                                                        <div className="movie-item-content-buttom">
                                                            <div className="movie-item-title">
                                                                <Link to={`/news/${firstBlog.id}`}>{firstBlog.title}</Link>
                                                            </div>
                                                            <div className="movie-item-beta">
                                                                <div className="movie-details">
                                                                    <Link to={`/news/${firstBlog.id}`} className="btn btn-button button-detals red-bg">details</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : null
                                        }
                                    </div>
                                    <div className="col-lg-4 col-sm-12 col-xs-12">
                                        <div className="latest-news-small">
                                            <div className="row">
                                                {
                                                    secondBlog.length ?
                                                        secondBlog.map((blog, key) => {
                                                            return <div className="col-xs-12 sm-width" key={key}>
                                                                <div className="movie-item-contents gradient5" style={{ height: '167px' }}>
                                                                    {/*<img src={blog.image} alt="" />*/}
                                                                    <Image
                                                                        src={blog.image}
                                                                        alt={blog.title}
                                                                        retry={{ count: 10, delay: 2 }}
                                                                        placeholderColor="#5f1a89"
                                                                    />
                                                                    <div className="movie-item-content">
                                                                        <div className="movie-item-content-buttom">
                                                                            <div className="movie-item-title">
                                                                                <Link to={`/news/${blog.id}`}  >{blog.title}</Link>
                                                                            </div>
                                                                            <div className="movie-item-beta">
                                                                                <div className="movie-details">
                                                                                    <Link to={`/news/${blog.id}`} className="btn btn-button button-detals red-bg">details</Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        })
                                                        : ('')
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        restBlog.length ?
                                            restBlog.map((blog, key) => (
                                                <div className="col-lg-4 col-sm-12 col-xs-12" key={key}>
                                                    <div className="latest-news-small">
                                                        <div className="col-xs-12 sm-width" key={key}>
                                                            <div className="movie-item-contents gradient5" style={{ height: '167px' }}>
                                                                <Image
                                                                    src={blog.image}
                                                                    alt={blog.title}
                                                                    retry={{ count: 10, delay: 2 }}
                                                                    placeholderColor="#5f1a89"
                                                                />
                                                                <div className="movie-item-content">
                                                                    <div className="movie-item-content-buttom">
                                                                        <div className="movie-item-title">
                                                                            <Link to={`/news/${blog.id}`}  >{blog.title}</Link>
                                                                        </div>
                                                                        <div className="movie-item-beta">
                                                                            <div className="movie-details">
                                                                                <Link to={`/news/${blog.id}`} className="btn btn-button button-detals red-bg">details</Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )) : ('')
                                    }

                                    <div className="container">
                                        <Link to="/news" className="pull-right btn btn-button button-1 white-outline btn-block hidden-lg hidden-md" style={{ marginTop: '22px' }}>View All News</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                {/*<div className="widget special-widget mb-10">*/}
                                {/*    <div className="widget-title special-widget-title">*/}
                                {/*        <i className="fa fa-list"></i><span>Student Reward</span>*/}
                                {/*    </div>*/}
                                {/*    <div className="widget-content notice-app">*/}
                                {/*        <a href="/student-reward" target="_blank" style={{ display: 'inline-block' }}>*/}
                                {/*            <p style={{ display: 'inline-block' }}>Click to go student reward program</p>*/}
                                {/*        </a>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <Notice />
                                <Poll />
                                <Vote />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
