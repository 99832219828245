import React, { useEffect, useState} from 'react'
import Slider from './components/concession/slider'
import ConcessionItem from './components/concession/concessionItem'
import {apiCall, postCallWithresponse} from "../api/apiCall";
import {FOOD_LIST, FOOD_RECOMMENDATION, FOOD_VOTE} from "../api/apiRoutes";
import { loadReCaptcha,ReCaptcha  } from 'react-recaptcha-v3'
import swal from "sweetalert";
import {connect} from "react-redux";
import FoodBanner from "../images/food-banner.jpg";

function Concession(props){
    const [recommendation, setRecommendation] = useState('')
    const [foodList, setFoodList] = useState([])
    const [recaptchaToken, setRecaptchaToken] = useState('')
    const getFoodList = async () => {
        setFoodList(await apiCall(FOOD_LIST, {}) ?? [])
    }
    const verifyCallback = (data) => {
        setRecaptchaToken(data)
    }

    const handleVoteSubmit = async (e) => {
        e.preventDefault()
        const result = await postCallWithresponse(FOOD_VOTE, {
            food_id: e.target.value,
            recaptchaToken: recaptchaToken
        })
        if(result?.code === 200){
            swal('Success:', result?.message, 'success');
        } else if(result?.code === 401){
            swal('Success:', 'Your session expired please reload and submit again', 'error');
        } else {
            swal('Already Submitted:', result?.message, 'info');
        }
    }

    const handleRecommendationSubmit = async (e) => {
        e.preventDefault()
        const result = await postCallWithresponse(FOOD_RECOMMENDATION,{
            name: recommendation,
            recaptchaToken: recaptchaToken
        })
        if(result?.code === 200){
            setRecommendation('')
            swal('Success:', result?.message, 'success');
        } else if(result?.code === 401){
            swal('Success:', 'Your session expired please reload and submit again', 'error');
        } else {
            swal('Error:', result?.message, 'error');
        }
    }

    useEffect(() => {
        loadReCaptcha('6Lddr8QUAAAAABh9QxGA9GxgEQzwBA4Y1NObXEcH');
        getFoodList()
    },[])
    return (
        <>
            <div className="inner-page">
                <div className="faq-page pt-75 pb-0">
                    <div className="blog-page">
                        <div className="container">
                            <div className="row">
                                <aside className="col-lg-8 col-md-8 col-sm-6 col-xs-12">
                                    <div className="details-page">
                                        <div className="details-img">
                                            <img src={FoodBanner} alt="" style={{width:'100%'}}></img>
                                        </div>
                                        {/*<Slider />*/}

                                        <ConcessionItem />

                                    </div>
                                </aside>
                                <aside className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                    <div className="widget mb-50">
                                        <div className="widget-title">
                                            <i className="fa fa-list"></i><span>Cineplex Favorite Food</span>
                                        </div>
                                        <div className="widget-content fav-food">
                                            <h4>What is your favorite food at STAR Cineplex?</h4>
                                            <form>
                                                <ul className="list-group">
                                                    {
                                                        foodList.length ?
                                                            foodList.map((food, key) => {
                                                                return <li className="list-group-item" key={key}>
                                                                    <input type="checkbox" onClick={handleVoteSubmit} name="food_vote_id" value={food.id} id={'vote-' + food.id} />
                                                                    {food.food_name}
                                                                </li>
                                                            }) : ('')
                                                    }
                                                </ul>
                                            </form>
                                            <form onSubmit={handleRecommendationSubmit}>
                                                <h4>What food or beverage would you like to have at STAR Cineplex?"</h4>
                                                <input className="form-control" onChange={(e) => setRecommendation(e.target.value)} type="text" name="" value={recommendation} required />
                                                <ReCaptcha
                                                    sitekey="6Lddr8QUAAAAABh9QxGA9GxgEQzwBA4Y1NObXEcH"
                                                    action='homepage'
                                                    verifyCallback={verifyCallback}
                                                />
                                                <button className="btn btn-button red-bg button-1">Submit</button>
                                            </form>
                                        </div>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = function(state) {
    return {
        token: state.token,
        user_id: state.user_id,
    }
}

export default connect(mapStateToProps)(Concession);