import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom';
import {RECENT_BLOGS} from '../../api/apiRoutes'
import {apiCall} from "../../api/apiCall";
import BlogXS from './blogXS'
export default function recentBlog(props){
    const [blogList, setBlogList] = useState([])

    const getRecentBlog = async () => {
        setBlogList(await apiCall(RECENT_BLOGS,{}))
    }

    useEffect(() => {
        getRecentBlog()
    },[])
    return (
        <>
            <div className="widget">
                <div className="widget-title"><i className="fa fa-list"></i><span>Recent Post</span>
                </div>
            </div>
            <div className="widget-recent-post">
                <div className="item">
                    {
                        blogList.length ?
                            blogList.map((blog, key) => {
                                return <BlogXS blog={blog} key={key}/>
                            })
                            : ('')
                    }
                </div>
            </div>
        </>
    )
}
