import { basePath } from '../config/configFile';
import axios from "axios";
import Cookies from 'universal-cookie';
import {store} from '../redux/store'
import {GET_TOKEN} from '../api/apiRoutes'
import { SET_TOKEN } from '../redux/action';

const cookies = new Cookies();

export const createUserCookie =async () =>{
    let id = cookies.get('user_id') ?? await makeid(20);
    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    await cookies.set('user_id', id, {
        expires: tomorrow
    });
    var token = await getToken(id)
    await store.dispatch(SET_TOKEN({
        token: token,
        user_id : id
    }))
    return {
        token: token,
        user_id : id
    }
}

async function getToken(user){
    return await axios.post(GET_TOKEN, {
        'user_id': user ? user : cookies.get('user_id')
    }, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        }
    }).then( async (response) => {
        if (response.data.code === 200) {
            cookies.set('token', response.data.data, {
                maxAge: 18000
            });
            axios.defaults.headers.common = {'Authorization': `Bearer ${response.data.data}`};
            // window.location.reload()
            return await response.data.data
        }
        return null
    });
}

function makeid(length){
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result + Date.now();
}
