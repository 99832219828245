import React, { Component } from 'react'
import Career from '../images/career.jpeg'

export default function (){
    return (
        <div>
            <div className="inner-page">
                <div className="faq-page pt-75 pb-0">
                    <div className="blog-page">
                        <div className="container">
                            <div className="row">
                                <div className="details-page">
                                    <div className="details-img">
                                        <img src={Career}  alt=""/>
                                    </div>
                                    <div className="details-contents">
                                        <div className="details-content">

                                            <div className="details-dectiontion">
                                                <h2 className="title_page">Career</h2>
                                                <h4 className="mb-2 font-20">Customer Care Officer-Operation (Full time & Part-time)</h4>
                                                <p className="font-weight-bold font-15"> Required Academic Qualifications </p> 

                                                <ul style={{listStyleType:'disc', paddingLeft:'30px'}}>
                                                    <li>
                                                        Graduate from any recognized college/university. 
                                                    </li> 
                                                </ul>  
                                                <p className="font-weight-bold font-15"> Required Experience </p> 

                                                <ul style={{listStyleType:'disc', paddingLeft:'30px'}}>
                                                    <li>
                                                    1-year relevant experience in a reputed organization is preferred.
                                                    </li> 
                                                </ul>
                                             
                                                <p className="font-weight-bold font-15"> Required Competencies  </p> 

                                                <ul className="mb-2" style={{listStyleType:'disc', paddingLeft:'30px'}}>
                                                    <li>
                                                        Excellent communication skill.
                                                    </li>
                                                    <li>
                                                    	Candidate must be smart & energetic
                                                    </li>
                                                    <li>
                                                    	Able to work under stress.
                                                    </li>
                                                </ul>
                                                <p className="font-15 mb-2"> <span className="font-weight-bold"> Job Location :  </span> Dhaka, Bogura, Chittagong  </p>

                                                <p className="font-15 font-weight-bold"> Salary & Other Benefits: </p>
                                                <ul style={{listStyleType:'disc', paddingLeft:'30px'}}>
                                                    <li>
                                                    	Salary: Negotiable 
                                                    </li> 
                                                </ul>

                                                <p className="font-15 mb-1"> If you meet the above requirements, please submit your resume with one photograph and two references. Application should be sent to the following address:</p>  

                                                <p className="font-15 font-weight-bold"> Mailing Address: <a href="mailto:Jobs@Cineplexbd.com">  Jobs@Cineplexbd.com </a> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
