import React, {Component, useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery'
import ModalVideo from "react-modal-video";
import Image from "react-graceful-image";

export default function movieInfo({movieInfo, movieId}){

    const [isOpen, setIsOpen] = useState(false)
    const [url, setUrl] = useState(null)

    const handleVideoPlay = (e,url) => {
        e.preventDefault();
        setIsOpen(true)
        var url_id = url.split('=')[1]
        setUrl(url_id)
    }

    useEffect(() => {

    },[])
    return (
        <>
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId={url} onClose={() => setIsOpen(false)} />
            <div className="col-md-4">
                <div className="selebritie-list">
                    <div className="col-lg-4 col-md-5 col-ms-4 col-xs-12 sm-width">
                        <div className="selebritie-img">
                            <Image
                                src={movieInfo.img}
                                alt={movieInfo.title}
                                retry={{ count: 10, delay: 2 }}
                                placeholderColor="#5f1a89"
                                style={{height:'400px'}}/>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-7 col-ms-8 col-xs-12 sm-width">
                        <div className="selebritie-contetn animation">
                            <h3>{movieInfo.title}</h3>
                            <div className="ratting">
                                <span className="fa fa-star"></span> {movieInfo.rating}
                            </div>
                            <div className="time-duration"><i className="fa fa-clock-o"></i>{movieInfo.length}</div>
                            <div className="dec-review-meta">
                                <ul>
                                    <li><span>Category <label>:</label></span><a href="">{movieInfo.category}</a></li>
                                    <li><span>Actor <label>:</label></span><a href="">{movieInfo.actor}</a></li>
                                    <li><span>Genre <label>:</label></span><a href="">{movieInfo.genre}</a></li>
                                    <li><span>release <label>:</label></span><a href="">{movieInfo.release}</a></li>
                                    <li><span>language <label>:</label></span><a href="">{movieInfo.language}</a></li>
                                </ul>
                            </div>
                            <div className="social-links">
                                <strong className="animation">Share :</strong>
                                <a href="" className="socila-fb"><span className="fa fa-facebook"></span></a>
                                {/*<a href="" className="socila-tw"><span className="fa fa-twitter"></span></a>*/}
                                {/*<a href="" className="socila-sk"><span className="fa fa-skype"></span></a>*/}
                                {/*<a href="" className="socila-pin"><span className="fa fa-pinterest"></span></a>*/}
                                {/*<a href="" className="socila-ins"><span className="fa fa-instagram"></span></a>*/}
                            </div>
                            <div className="details-buttons">
                                <Link to={`/detail/${movieId}`} className="btn btn-button red-bg button-1">Details</Link>
                                {
                                    movieInfo.video?
                                        <a href='#' onClick={(e) => handleVideoPlay(e, movieInfo.video)} className="flat-icons video-pop btn btn-button btn-border button-1" style={{ cursor: "pointer" }}>Watch Trailer</a>
                                        :
                                        <a href='#' onClick={e => e.preventDefault()} data-video-url={movieInfo.video} className="flat-icons btn btn-button btn-border button-1" style={{ cursor: "pointer" }}>Watch Trailer</a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
