import React, {  } from 'react'
// import Reply from './reply';
import USER_AVATAR from '../../../images/default-user.png'

export default function comment({commentData}){
    return(
        <>
            {
                commentData.length ?
                    commentData.map((comment, key) => {
                        return <React.Fragment key={key} >
                            <ol className="comment-list">
                                <li className="single-comment">
                                    <div className="comment-body">
                                        <div className="comment-img">
                                            <img src={USER_AVATAR} alt="" />
                                        </div>
                                        <div className="comment-content">
                                            <div className="comment-header">
                                                <h3 className="comment-title">{comment.name}</h3>
                                            </div>
                                            <p>{comment.comment}</p>
                                            <div className="blog-details-reply-box">
                                                <div className="comment-time">{comment.time}</div>
                                                {/*<div className="comment-reply">*/}
                                                {/*    <Link to="" className="reply" comment_id = {comment.id} onClick={this.handleparentComment}>reply</Link>*/}
                                                {/*</div>*/}
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ol>
                            {
                                comment.reply.length ? <Reply replyData={comment.reply} /> : ('')
                            }
                        </React.Fragment>
                    })
                    : ('')
            }
        </>
    )
}
