import React, {useState} from 'react'

export default function writers({writerData}){
    const [data, setData] = useState(JSON.parse(writerData))
    return (
        <>
            {
                data.length ?
                    <React.Fragment>
                        <h2 className="title hadding-area">Writers</h2>
                        <ul className="mvcast-item">
                            {
                                data.map((writer,key) => {
                                    return <React.Fragment key={key}>
                                        {
                                            writer.url ?
                                                <a href={writer.url} target="_blank">
                                                    <li className="cast-it" >
                                                        <img src={writer.image} alt="" />
                                                        <p>{writer.name}</p>
                                                    </li>
                                                </a>
                                                :
                                                <li className="cast-it" >
                                                    <img src={writer.image} alt="" />
                                                    <p>{writer.name}</p>
                                                </li>

                                        }
                                    </React.Fragment>
                                })
                            }
                        </ul>
                    </React.Fragment>
                    :''
            }
        </>
    )
}
