import React, {Component, useEffect, useState} from 'react'
import OwlCarousel from 'react-owl-carousel';
import {apiCall} from "../../../api/apiCall";
import {ABOUT_SLIDER} from "../../../api/apiRoutes";
import Image from "react-graceful-image";

export default function aboutSlider(){

    const [option, setOption] = useState({
        0: {
            items: 1,
            loop: true
        }
        , 480: {
            items: 1,
            loop: true
        }
        , 568: {
            items: 1,
            loop: true
        }
        , 768: {
            items: 1,
            loop: true
        }
        , 980: {
            items: 1,
            loop: true
        }
        , 1080: {
            items: 1,
            loop: true
        }
        , 1200: {
            items: 1,
            loop: true
        }
        , 1300: {
            items: 1,
            loop: true
        }
        , 1600: {
            items: 1,
            loop: true
        }
        , 1920: {
            items: 1,
            loop: true
        }
    })
    const [list, setList] = useState([])

    const getSliderData = async () => {
        setList(await apiCall(ABOUT_SLIDER,{}) ?? [])
    }

    useEffect(() => {
        getSliderData()
    },[1])

    return (
        <>
            <div className="slider-section" id="about-slide">
                {
                    list.length ?
                        <OwlCarousel
                            autoplay={true}
                            autoplaySpeed={300}
                            nav={true}
                            stagePadding={0}
                            margin={30}
                            center={true}
                            dots={false}
                            smartSpeed={1200}
                            navText={["<i class='flaticon-send'></i>", "<span class='flaticon-send'></span>"]}
                            autoplayHoverPause={true}
                            responsive={option}
                        >
                            {
                                list.length ?
                                    list.map((data,key)=>{
                                        return (
                                            <div className="item" key={key}>
                                                <div className="details-img">
                                                    {/*<img src={data.image} alt=""/>*/}
                                                    <Image
                                                        src={data.image}
                                                        alt={data.title}
                                                        retry={{ count: 10, delay: 2 }}
                                                        placeholderColor="#5f1a89"
                                                    />
                                                </div>
                                                <div className="details-slider-content">
                                                    <h4>{data.title}</h4>
                                                </div>
                                            </div>
                                        )
                                    })
                                    : null
                            }
                        </OwlCarousel>
                        : null
                }
            </div>
        </>
    )
}
