import React, { useEffect, useState } from 'react'
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import Home from '../views/homeComponent';
import SingleMovie from '../views/movieDetail';
import MovieList from '../views/movieListComponent';
import ShowTime from '../views/showtime';
import AboutCineplex from '../views/aboutcineplex';
import Member from '../views/member';
import Blog from '../views/blogList';
import Concession from '../views/concessionMainComponent';
import Career from '../views/careerMainComponent';
import SingleBlog from '../views/blogDetail';
import Privacy from '../views/privecy'
import Ecard from '../views/giftCard'
import Gallery from '../views/galleryMainComponent'
import Contact from '../views/contactComponent';
import StudentReward from '../views/studentReward'
import TicketPrice from '../views/ticketPrice'
import Default from '../views/defaultPage';
import Header from '../views/layout/header'
import Footer from '../views/layout/footer'
import ProtectedRoute from './protectedRoute'
import Loader from "../views/common/loader";
import {ShowTimeFilterProvider} from '../context/showTimeFilterContext'

function Router() {
    const [isHeaderLoad, setIsHeaderLoad] = useState(false)
    const [isHomePage, setIsHomePage] = useState(false)
    const loadHeader = async (data) => {
        setIsHeaderLoad(data)
    }
    useEffect(() => {
        if (['/', '/show-time'].includes(window.location.pathname)) {
            setIsHomePage(true)
        }
    }, [])
    return (
        <BrowserRouter>
            <Header isLoad={loadHeader} />
            {
                isHeaderLoad ?
                    <Switch>
                        <ProtectedRoute exact path="/" ><Home /></ProtectedRoute>
                        <ProtectedRoute exact path="/movie-list" ><MovieList /></ProtectedRoute>
                        <ProtectedRoute exact path="/news" ><Blog /></ProtectedRoute>
                        <ProtectedRoute exact path="/news/:id" ><SingleBlog /></ProtectedRoute>
                        <ProtectedRoute exact path="/show-time" >
                            <ShowTimeFilterProvider>
                                <ShowTime />
                            </ShowTimeFilterProvider>
                        </ProtectedRoute>
                        <ProtectedRoute exact path="/detail/:id" ><SingleMovie /></ProtectedRoute>
                        <ProtectedRoute exact path="/about-cineplex" ><AboutCineplex /></ProtectedRoute>
                        {/*<ProtectedRoute exact path="/member" ><Member /></ProtectedRoute>*/}
                        <ProtectedRoute exact path="/concession" ><Concession /></ProtectedRoute>
                        <ProtectedRoute exact path="/career" ><Career /></ProtectedRoute>
                        <ProtectedRoute exact path="/e-card" ><Ecard /></ProtectedRoute>
                        <ProtectedRoute exact path="/privacy" ><Privacy /></ProtectedRoute>
                        <ProtectedRoute exact path="/gallery" ><Gallery /></ProtectedRoute>
                        <ProtectedRoute exact path="/contact" ><Contact /></ProtectedRoute>
                        <ProtectedRoute exact path="/ticket-price" ><TicketPrice /></ProtectedRoute>
                        {/*<ProtectedRoute exact path="/student-reward" ><StudentReward /></ProtectedRoute>*/}
                        <Route component={Default} />
                    </Switch>
                    :
                    (isHomePage ? <Loader /> : null)
            }
            <Footer />
        </BrowserRouter>
    )
};
export default Router;
