import React, { useContext, useEffect } from 'react'
import axios from 'axios';
import swal from 'sweetalert';
import { createUserCookie } from '../helpers/Helper'
// import { store } from '../redux/store'
// import { SET_TOKEN } from '../redux/action';
import Cookies from 'universal-cookie';

const cookie = new Cookies()
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.common = {'Authorization': `Bearer ${cookie.get('token')}`};

/**
* @param {string} url
* @param {object} data
* @param {object} config
*/

export const postCallHeader = async (url, data, config={}) => {
    return await axios.post(url, data).then(async (response) => {
        if (response.data.code === 200) {
            return await response.data.data
        } else if (response.data.code === 401) {
            await createUserCookie()
            return await postCallHeader(url, data, config)
        } else {
            swal('Error:', 'Network connection failed!', 'error');
        }
        return response.data.data
    }).catch((error) => {
        swal('Error:', 'Network connection failed!', 'error');
    });
}

export const apiCall = async (url, data, config={}) => {
    return await axios.post(url, data).then(async (response) => {
        if (response.data.code === 200) {
            return response.data.data
        } else if (response.data.code === 401) {
            await createUserCookie()
            await apiCall(url, data, config)
        } else {
            swal('Error:', 'Network connection failed!', 'error');
        }
        return response.data.data
    }).catch((error) => {
        swal('Error:', 'Network connection failed!', 'error');
    });
}

export const postCallWithresponse = async (url, data, config={}) => {
    return await axios.post(url, data).then( async (response) => {
        if (response.data.code === 401) {
            await createUserCookie()
            await postCallWithresponse(url, data, config)
        }
        return response.data
    }).catch((error) => {
        swal('Error:', 'Network connection failed!', 'error');
    });
}