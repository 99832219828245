import React from 'react'
import $ from 'jquery'
import {store} from '../../redux/store'
import Close from '../../images/close.svg'

export default function locationModal({hall}) {
    const handleTheaterId = async (theater) => {
        if (theater) {
            localStorage.setItem('theater', JSON.stringify(theater))
            store.dispatch({
                type:'SET_LOCATION',
                payload:theater
            })
        }
    }
    return (
        <>
            <div className="modal fade location_modal" id="loc_cng_modal" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal">
                                <img src={Close} alt="" />
                            </button>
                            <h4 className="modal-title text-center">Select Your Theatre</h4>
                        </div>
                        <div className="modal-body location-modal-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="location_list">
                                        <ul className="row">
                                            {(hall && hall.length) ? hall.map(theater => {
                                                return <li className="col-md-12" key={theater.id}>
                                                    <button className="text-left" type="button" onClick={() => handleTheaterId(theater)} data-dismiss="modal">
                                                        {theater.location_name}
                                                        <p data-dismiss="modal">{theater.location}</p>
                                                    </button>
                                                </li>
                                            })
                                            : null}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
