import React from 'react'
import '../../../js-files/jquery.lighter'
export default function image({imageData}) {
      return (
          <div className="item">
                <div className="gallery-contetn gradient">
                      <img src={imageData.url} alt="" style={{height:'250px'}}/>
                      <div className="gallery-hover">
                            <a data-height='720' data-lighter={imageData.url} data-width='1280' href={imageData.url}><span className="fa fa-search"></span></a>
                      </div>
                </div>
          </div>
      )
}
